/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useMemo, useEffect } from "react";
import { HiTrash, HiPencilAlt } from "react-icons/hi";
import {
  PageLayout,
  DialogForm,
  TableSkeleton,
  VehicleModal,
} from "../../components";
import { useWorkspace } from "../../context/WorkspaceContext";
import { useCache } from "../../context/CacheContext";
import { useVehicles, useParameters, useCompanies } from "../../hooks";
import { useTranslation } from "react-i18next";
import { ITableRow, IVehicle, ParameterType } from "../../types";
import { Company, Vehicle, Parameter } from "../../models";
import { EmptyState } from "../../components";
import { emptyVehicleIllustration } from "../../components/atoms/Icons/illustrations";
import { VehicleList } from "../../components/organisms/Vehicle";

export const VehiclesPage: FC = function () {
  const [isUpsertModalOpen, openUpsertModal] = useState(false);
  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const [vehicleCategories, setVehicleCategories] = useState([] as Parameter[]);
  const [workspaceCompanies, setWorkspaceCompanies] = useState([] as Company[]);
  const [isLoading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [splitIsOpen, setSplitIsOpen] = useState(false);
  const [vehicleData, setVehicleData] = useState(Vehicle.default());

  const { activeWorkspace } = useWorkspace();
  const { updateCacheKey } = useCache();
  const query = useVehicles(activeWorkspace?.workspaceId ?? "");
  const categoriesQuery = useParameters(
    activeWorkspace?.workspaceId ?? "",
    ParameterType.VehicleType,
  );
  const companiesQuery = useCompanies(activeWorkspace?.workspaceId ?? "");
  const { t } = useTranslation(["common", "inventory", "vehicles"]);

  const isQueryLoading = useMemo(
    () =>
      query.isLoading ||
      query.isRefetching ||
      categoriesQuery.isLoading ||
      categoriesQuery.isRefetching ||
      companiesQuery.isLoading ||
      companiesQuery.isRefetching,
    [query, categoriesQuery, companiesQuery],
  );

  const vehicles = useMemo(() => query.data ?? [], [query]);

  const categories = useMemo(
    () => categoriesQuery.data ?? [],
    [categoriesQuery],
  );

  const companies = useMemo(() => companiesQuery.data ?? [], [companiesQuery]);

  useEffect(() => {
    if (!isQueryLoading) {
      setVehicleCategories(categories);
    }
  }, [isQueryLoading, categories]);

  useEffect(() => {
    if (!isQueryLoading) {
      setWorkspaceCompanies(companies);
    }
  }, [isQueryLoading, companies]);

  useEffect(() => {
    if (!isQueryLoading) {
      setLoading(false);
      setTableRows(
        vehicles.map((vehicle) => {
          return {
            id: vehicle.id,
            cells: [
              {
                id: "categoryCode",
                children: vehicle.categoryCode,
                showOnSmallScreen: true,
              },
              {
                id: "registrationNumber",
                children: vehicle.registrationNumber,
                showOnSmallScreen: true,
              },
              {
                id: "name",
                children: vehicle.name,
              },
            ],
            actions: [
              {
                id: "edit",
                icon: <HiPencilAlt />,
                onActionClick: () => {
                  setVehicleData(vehicle);
                  openUpsertModal(true);
                },
              },
              {
                id: "delete",
                icon: <HiTrash />,
                color: "failure",
                onActionClick: () => {
                  setVehicleData(vehicle);
                  openDeleteModal(true);
                },
              },
            ],
          };
        }),
      );
    }
  }, [isQueryLoading, vehicles]);

  return (
    <PageLayout>
      {isLoading ? (
        <TableSkeleton />
      ) : tableRows.length === 0 ? (
        // Render empty state when there are no table rows
        <EmptyState
          icon={emptyVehicleIllustration}
          title={t("vehicles:empty_state.title")}
          description={t("vehicles:empty_state.description")}
          buttonText={t("vehicles:empty_state.button_text")}
          buttonClick={() => {
            setVehicleData(Vehicle.default());
            openUpsertModal(true);
          }}
        />
      ) : (
        <VehicleList
          vehicles={vehicles}
          isLoading={isQueryLoading}
          companies={companies}
          isOpen={setSplitIsOpen}
          createOrUpdate={(formData: Vehicle) => {
            setVehicleData(formData);
            openUpsertModal(true);
          }}
        />
      )}

      <VehicleModal
        data={vehicleData}
        cateogries={vehicleCategories}
        companies={workspaceCompanies}
        isShowing={isUpsertModalOpen}
        onConfirm={(formData: IVehicle) => {
          if (Vehicle.isNew(vehicleData))
            Vehicle.create(activeWorkspace?.workspaceId ?? "", formData);
          else Vehicle.update(vehicleData, formData);
          openUpsertModal(false);
          updateCacheKey();
          setLoading(true);
        }}
        onCancel={() => openUpsertModal(false)}
      />

      <DialogForm
        title={t("inventory:delete_vehicle") + " " + vehicleData.name + "?"}
        show={isDeleteModalOpen}
        toggleModal={() => openDeleteModal(false)}
        showHeader={false}
        buttonConfirmColor="failure"
        dismissible
        confirmButton={() => {
          Vehicle.delete(vehicleData);
          openDeleteModal(false);
          updateCacheKey();
          setLoading(true);
        }}
        buttonConfirmText={t("common:confirm")}
        buttonConfirmPrependIcon={<HiTrash className="mr-2 h-4 w-4" />}
        buttonCloseText={t("common:close")}
      />
    </PageLayout>
  );
};
