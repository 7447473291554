import { useState, type FC } from "react";
import { Button, Checkbox, Label } from "flowbite-react";
import { Dialog } from "../..//molecules/Dialog";
import { useTranslation } from "react-i18next";
import { OrderStatus } from "../../../types/order/IOrder";

export interface UpdateStatusProp {
  show: boolean;
  currentStatus: OrderStatus;
  isLoading: boolean;
  toggleModal: () => void;
  confirmButton: (status: OrderStatus) => void;
}
interface DialogContentProps {
  currentStatus: OrderStatus;
  toggleModal: () => void;
  confirmButton: (status: OrderStatus) => void;
}
export const UpdateStatus: FC<UpdateStatusProp> = (props) => {
  return (
    <Dialog
      size="md"
      denseHeader
      show={props.show}
      toggleModal={props.toggleModal}
      hideButtons={true}
      dismissible
      content={
        <DialogContent
          currentStatus={props.currentStatus}
          toggleModal={props.toggleModal}
          confirmButton={props.confirmButton}
        />
      }
    />
  );
};

const DialogContent: FC<DialogContentProps> = (props) => {
  const { t } = useTranslation(["common", "orders", "stats"]);
  const [newStatus, setNewStatus] = useState<OrderStatus>(props.currentStatus);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  return (
    <div className="flex flex-col items-start justify-between gap-4">
      <p className="text-xl font-medium text-gray-900">
        {t("orders:ongoing_orders.update_status_title")}
      </p>
      <p className="text-md text-lgb-grey-500">
        {t("orders:ongoing_orders.update_status_description")}
      </p>

      <div className="w-full">
        <Label
          className="dark:text-lgb-grey-400"
          htmlFor="new-status"
          value={t("orders:ongoing_orders.update_status_new_status")}
        />
        <form className="w-full">
          <select
            id="new-status"
            className="w-full border p-2.5 text-sm rounded-lg relative border-gray-300 dark:border-gray-700 dark:border-lgb-grey-600 dark:bg-lgb-dark-background dark:text-white"
            defaultValue={props.currentStatus}
            required
            onChange={(e) => setNewStatus(e.target.value as OrderStatus)}
          >
            <option value={OrderStatus.Completed}>
              {t("stats:orderStatus.completed")}
            </option>
            <option value={OrderStatus.InProgress}>
              {t("stats:orderStatus.in_progress")}
            </option>
            <option value={OrderStatus.New}>
              {t("stats:orderStatus.scheduled")}
            </option>
          </select>
        </form>
      </div>

      <div className="flex items-center gap-2 py-4 px-4 bg-lgb-grey-100 rounded">
        <Checkbox
          id="terms"
          className="text-lgb-primary dark:text-lgb-primary-lighten"
          onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
        />
        <Label htmlFor="terms" className="text-lgb-grey-500 font-normal">
          {t("orders:ongoing_orders.update_status_confirmation_message")}
        </Label>
      </div>

      <div className="flex gap-4">
        <Button color="gray" onClick={() => props.toggleModal()}>
          {t("common:close")}
        </Button>
        <Button
          onClick={() => props.confirmButton(newStatus)}
          color="primary"
          disabled={!hasAcceptedTerms || newStatus === props.currentStatus}
        >
          {t("common:update")}
        </Button>
      </div>
    </div>
  );
};
