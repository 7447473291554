import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { DialogForm, SplitTable } from "../..";
import { SplitTableRef } from "../../molecules/SplitTable/SplitTable";
import { DropdownButton } from "../../atoms/Button/DropdownButton";
import {
  HiOutlineChevronDown,
  HiTrash,
  HiPencilAlt,
  HiOutlinePlus,
} from "react-icons/hi";

import { Product } from "../../../models";
import { useCache } from "../../../context/CacheContext";
import { Button } from "flowbite-react";
import { ProductDetails } from "./ProductDetails";
import isSmallScreen from "../../../helpers/is-small-screen";

interface ProductsListProps {
  products: Product[];
  isLoading: boolean;
  isOpen: (val: boolean) => void; // toggles the right pane
  createOrUpdate: (formData: Product) => void;
}

export const ProductsList: FC<ProductsListProps> = ({
  products,
  isLoading,
  isOpen,
  createOrUpdate,
}) => {
  const { t } = useTranslation(["common", "products"]);
  const navigate = useNavigate();
  const { updateCacheKey } = useCache();

  const [openedProduct, setOpenedProduct] = useState<Product | undefined>();
  const [productData, setProductData] = useState<Product>(Product.default());
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);

  const splitTableRef = useRef<SplitTableRef>(null);

  // Update opened product
  const updateOpenedProduct = (prod: Product | undefined) => {
    setOpenedProduct(prod);

    if (prod) {
      isOpen(true);
      const productName = prod.descriptionShort || "unknown";
      const paramSafeName = productName.toLowerCase().replace(/\s+/g, "-");
      navigate(`/resources/products?product=${paramSafeName}`, {
        replace: true,
      });
      splitTableRef.current?.setSplit(true);
    } else {
      isOpen(false);
      navigate(`/resources/products`, { replace: true });
      splitTableRef.current?.setSplit(false);
    }
  };

  // Filter products based on search text
  useEffect(() => {
    if (searchText.trim().length > 0) {
      const lowerCaseSearchText = searchText.toLowerCase();
      setFilteredProducts(
        products.filter((prod) =>
          prod.descriptionShort.toLowerCase().includes(lowerCaseSearchText),
        ),
      );
    } else {
      setFilteredProducts(products);
    }
  }, [searchText, products]);

  // Table rows
  const tableRows = filteredProducts.map((prod) => ({
    id: prod.id,
    isActive: prod.id === openedProduct?.id,
    onRowClickData: prod,
    cells: [
      {
        id: "descriptionShort",
        children: prod.descriptionShort || t("products:unknown_product"),
      },
      {
        id: "description",
        children:
          (prod.description || "").length > (isSmallScreen() ? 50 : 100)
            ? `${(prod.description || "").substring(0, isSmallScreen() ? 50 : 100)}...`
            : prod.description || "",
        classOverride:
          "whitespace-normal p-4 text-base font-normal text-darkBlue dark:text-white overflow-hidden text-ellipsis min-w-[150px] line-clamp-3" +
          (openedProduct !== undefined ? " hidden" : ""),
      },
    ],
    actions: !openedProduct
      ? [
          {
            id: "edit",
            icon: <HiPencilAlt />,
            onActionClick: (e: React.MouseEvent) => {
              e.stopPropagation();
              createOrUpdate(prod);
            },
          },
          {
            id: "delete",
            icon: <HiTrash />,
            color: "failure",
            onActionClick: (e: React.MouseEvent) => {
              e.stopPropagation();
              setProductData(prod);
              setDeleteModalOpen(true);
            },
          },
        ]
      : [],
  }));

  // Handle product deletion
  const handleDelete = async () => {
    try {
      await Product.delete(productData);
      setDeleteModalOpen(false);
      updateCacheKey();

      if (openedProduct?.id === productData.id) {
        updateOpenedProduct(undefined);
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  return (
    <>
      {/* Header */}
      <div className="flex justify-between items-center mb-4 p-4">
        <div>
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            {t("products:title")}
          </h1>
          <p className="text-lgb-grey-600 text-base pt-2 pb-4 dark:text-lgb-grey-200">
            {t("products:description")}
          </p>
        </div>
        <Button
          color="light"
          onClick={() => {
            createOrUpdate(Product.default());
          }}
          className="flex items-center"
        >
          <HiOutlinePlus className="mr-2 h-5 w-5" />
          {t("products:new_product")}
        </Button>
      </div>

      {/* SplitTable */}
      <SplitTable
        ref={splitTableRef}
        searchable
        hasActions
        isLoading={isLoading}
        tableRows={tableRows}
        tableHeaders={[
          { id: "displayName", children: t("common:product") },
          {
            id: "description",
            children: t("common:description"),
            hidden: openedProduct !== undefined,
          },
        ]}
        onRowClick={(prod: Product | undefined) => updateOpenedProduct(prod)}
        updateTable={(text) => setSearchText(text)}
        topRightContent={
          <div className="flex items-center">
            {openedProduct && (
              <DropdownButton
                placement="bottom-end"
                buttonText={t("products:actions.buttonText")}
                buttonAppendIcon={<HiOutlineChevronDown className="ml-2" />}
                items={[
                  {
                    text: (
                      <p className="p-4 w-48">{t("products:actions.edit")}</p>
                    ),
                    onClick: () => {
                      createOrUpdate(openedProduct);
                    },
                  },
                  {
                    text: (
                      <p className="p-4 w-48 text-lgb-red-300 dark:text-lgb-red-200 hover:text-lgb-red-200 hover:dark:text-lgb-red-300">
                        {t("products:actions.delete")}
                      </p>
                    ),
                    onClick: () => {
                      setProductData(openedProduct);
                      setDeleteModalOpen(true);
                    },
                  },
                ]}
              />
            )}
          </div>
        }
        content={
          openedProduct ? (
            <ProductDetails product={openedProduct} />
          ) : (
            <div className="p-4 text-gray-600">
              {t("products:split.no_product_selected")}
            </div>
          )
        }
      />

      {/* Delete Confirmation Dialog */}
      <DialogForm
        title={`${t("products:actions.delete_dialogTitle")} ${
          productData.descriptionShort ?? ""
        }?`}
        dismissible
        show={isDeleteModalOpen}
        toggleModal={() => setDeleteModalOpen(false)}
        buttonConfirmColor="failure"
        confirmButton={handleDelete}
        buttonConfirmText={t("common:confirm")}
        buttonCloseText={t("common:close")}
      />
    </>
  );
};
