/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Modal, Spinner } from "flowbite-react";
import { useEffect, type FC } from "react";

export interface DialogProp {
  show: boolean;
  placeButtonsLeft?: boolean;
  buttonConfirmText?: string;
  confirmButtonColor?: string;
  buttonConfirmPrependIcon?: React.ReactNode;
  buttonCloseText?: string;
  buttonClosePrependIcon?: React.ReactNode;
  isLoading?: boolean;
  hideButtons?: boolean;
  hideTitle?: boolean;
  denseHeader?: boolean;
  hideHeader?: boolean;
  addDisabled?: boolean;
  title?: string;
  content?: React.ReactNode;
  dismissible?: boolean;
  /**
   * sm,md,lg,xl,2xl,
   * 3xl,4xl,5xl,6xl,7xl
   *
   * Defaults to 3xl
   */
  size?: string;
  toggleModal: () => void;
  confirmButton?: () => void;
}
/**
 * TODO:
 * Fullscreen on mobile
 */

export const Dialog: FC<DialogProp> = (props) => {
  useEffect(() => {
    if (!props.show) {
      //Remove 'overflow: hidden' which is being set to body by Flowbite.
      document.getElementsByTagName("body")[0].style.overflow = "";
    }
  }, [props.show]);

  const close = () => {
    props.toggleModal();
  };
  return (
    <div className="relative">
      {props.show && (
        <Modal
          dismissible={props.dismissible}
          show={props.show}
          size={props.size || "3xl"}
          className="animate-in fade-in duration-300"
          onClose={close}
        >
          <Modal.Header
            className={
              "dark:border-gray-700" + (props.denseHeader ? "" : " !p-6 !pb-2")
            }
          >
            {!props.hideTitle && props.title}
          </Modal.Header>
          <Modal.Body>
            <div>{props.content}</div>
          </Modal.Body>
          {!props.hideButtons && (
            <Modal.Footer
              className={
                "border-0 pt-0 gap-4" +
                (props.placeButtonsLeft ? " justify-start" : " justify-end")
              }
            >
              <div
                className={
                  "flex gap-4" +
                  (props.placeButtonsLeft ? "" : " flex-row-reverse")
                }
              >
                {!props.isLoading && (
                  <Button
                    disabled={props.addDisabled}
                    color={props.confirmButtonColor || "primary"}
                    onClick={() =>
                      props.confirmButton ? props.confirmButton() : null
                    }
                  >
                    {props.buttonConfirmPrependIcon}
                    {props.buttonConfirmText ?? "Accept"}
                  </Button>
                )}
                {props.isLoading && (
                  <Button color={props.confirmButtonColor || "primary"}>
                    <Spinner size={"sm"} />
                  </Button>
                )}
                <Button color="gray" onClick={close}>
                  {props.buttonClosePrependIcon}
                  {props.buttonCloseText ?? "Close"}
                </Button>
              </div>
            </Modal.Footer>
          )}
        </Modal>
      )}
    </div>
  );
};
