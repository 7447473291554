/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOrders } from "../hooks";
import { useWorkspace } from "../context/WorkspaceContext";
import { PageLayout } from "../components";
import { useTranslation } from "react-i18next";
import { EmptyState } from "../components";
import { emptyOrdersIllustration } from "../components/atoms/Icons/illustrations";
import { InvoiceList } from "../components/organisms/InvoiceList";
import { mockInvoices } from "../static/mock/invoices";
// Import the user context to check if the user is an admin:
import { useUserSession } from "../context/UserContext";
import { upcomingInvoicePage } from "../components/atoms/Icons/illustrations";

const Invoices: FC = function () {
  const [searchParams] = useSearchParams();
  const [splitIsOpen, setSplitIsOpen] = useState(false);
  const { activeWorkspace } = useWorkspace();
  const { t } = useTranslation(["common", "invoice", "validation"]);
  const navigate = useNavigate();
  const { userSession } = useUserSession(); // 1. Access user info

  searchParams.get("__firebase_request_key");

  const query = useOrders(activeWorkspace?.workspaceId ?? "", []);

  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query],
  );

  // For demonstration's sake, let's keep the "hasInvoices" variable as always true:
  const hasInvoices = true;

  // 2. If user is not an admin, display a 'Coming soon' message:
  if (!userSession?.user?.isAdmin) {
    return (
      <PageLayout>
        <div className="flex flex-col items-center justify-center py-8">
       {upcomingInvoicePage}
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4 mt-8">
            {t("common:invoice_coming_soon")}
          </h1>
          <p className="text-base text-gray-600 dark:text-lgb-grey-200">
            {t("common:feature_in_development")}
          </p>
        </div>
      </PageLayout>
    );
  }

  // 3. Otherwise, show the normal Invoices page
  return (
    <PageLayout>
      {/* Only show the header if there are invoices */}
      {hasInvoices && (
        <div className="flex justify-between items-start lg:p-4">
          <div className={splitIsOpen ? "hidden lg:block" : ""}>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              {t("invoice:page_title")}
            </h1>
            <p className="text-lgb-grey-600 text-base pt-2 pb-4 dark:text-lgb-grey-200">
              {t("invoice:page_description")}
            </p>
          </div>
        </div>
      )}

      {/* Render empty state if there are no invoices and the query is not loading */}
      {!isQueryLoading && !hasInvoices ? (
        <EmptyState
          icon={emptyOrdersIllustration}
          title={t("invoice:empty_state.general.title")}
          description={t("invoice:empty_state.general.description")}
          buttonText={t("invoice:empty_state.general.button_text")}
          buttonClick={() => navigate("/orders/new")}
        />
      ) : (
        <InvoiceList
          invoices={mockInvoices}
          isLoading={isQueryLoading}
          isOpen={setSplitIsOpen}
        />
      )}
    </PageLayout>
  );
};

export default Invoices;
