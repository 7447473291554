/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Stepper,
  StepperProp,
  Step,
  Dialog,
  OrderSummary,
} from "../../../components/";
import {
  CreateOrderStepsEnum,
  // ContactInfo,
  CustomerSelection,
  DriverSelection,
  ProductManagement,
  RouteSelection,
} from "./";
import isSmallScreen from "../../../helpers/is-small-screen";
import { Customer, Driver, Order, OrderProduct } from "../../../models";
import { Timestamp } from "../../../types";
import { IContactPerson, IRouteItem, IOrderProduct } from "../../../types";
import { Button } from "flowbite-react";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import { HiCheck } from "react-icons/hi";
import { useCustomers } from "../../../hooks";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { RouteSelectionRef } from "./steps/RouteSelection";
import { ProductSelectionRef } from "./steps/ProductManagement";

const CreateOrder: FC = function () {
  const { t } = useTranslation(["orders", "validation"]);
  const [currentStep, setStep] = useState(
    CreateOrderStepsEnum.CUSTOMER_SELECTION,
  );
  const [order, setOrder] = useState(Order.default());
  const [showSummaryDialog, setShowSummaryDialog] = useState(false);
  const [stepValidatedFailed, setStepValidatonFailed] = useState(false);
  const [transition, setTransition] = useState("");
  const { activeWorkspace } = useWorkspace();

  const fadeInTransition =
    "animate-in slide-in-from-right fade-out duration-300";
  const fadeOutTransition =
    "animate-in slide-in-from-left fade-out duration-300";
  // const hasMounted = useRef(false);
  const query = useCustomers(activeWorkspace?.workspaceId ?? "");
  const customers = query.data;

  const set = (
    key: string,
    val:
      | string
      | number
      | Customer
      | IRouteItem[]
      | IOrderProduct[]
      | IContactPerson[]
      | Driver
      | Timestamp
      | null,
  ) => {
    setOrder((order) => ({ ...order, [key]: val }) as Order);
  };

  const handleStepChange = (step: number) => {
    let validated = false;
    if (currentStep === CreateOrderStepsEnum.ROUTE_SELECTION) {
      handleRouteUpdate();
    }
    if (currentStep === CreateOrderStepsEnum.PRODUCT_MANAGEMENT) {
      handleProductUpdate();
    }
    //use latest order data
    setOrder((order) => {
      validated = Order.validateStep(order, currentStep);
      if (validated || step - 1 < currentStep) {
        setStepValidatonFailed(false);
        // Timeout to allow transition animation to run
        setTransition(
          step > currentStep ? fadeInTransition : fadeOutTransition,
        );
        setStep(step);
      } else {
        setStepValidatonFailed(true);
      }
      return order;
    });
  };

  const routeSelectionRef = useRef<RouteSelectionRef>(null);
  const productSelectionRef = useRef<ProductSelectionRef>(null);

  const handleRouteUpdate = () => {
    if (routeSelectionRef.current) {
      routeSelectionRef.current.updateOrderRoute();
    }
  };

  const handleProductUpdate = () => {
    if (productSelectionRef.current) {
      productSelectionRef.current.updateOrderProducts();
    }
  };

  const SummaryAndCompleteDialog = (
    <Dialog
      title={t("create.summary")}
      dismissible
      content={
        <OrderSummary order={order} close={() => setShowSummaryDialog(false)} />
      }
      show={showSummaryDialog}
      toggleModal={() => setShowSummaryDialog(false)}
      hideButtons
    />
  );

  const createOrderSteps = {
    currentStep: currentStep,
    steps: [
      {
        number: 1,
        description: isSmallScreen()
          ? t("create.customer_selection.stepper_desc_short")
          : t("create.customer_selection.stepper_desc"),
      } as Step,
      {
        number: 2,
        description: isSmallScreen()
          ? t("create.route_selection.stepper_desc_short")
          : t("create.route_selection.stepper_desc"),
        disabled: !Order.validateCustomer(order.customer),
      } as Step,
      {
        number: 3,
        description: isSmallScreen()
          ? t("create.product_management.stepper_desc_short")
          : t("create.product_management.stepper_desc"),
        disabled:
          !Order.validateRoute(order.route) ||
          !Order.validateCustomer(order.customer),
      } as Step,
      {
        number: 4,
        description: isSmallScreen()
          ? t("create.driver_selection.stepper_desc_short")
          : t("create.driver_selection.stepper_desc"),
        disabled:
          !Order.validateCargo(order.cargo) ||
          !Order.validateRoute(order.route) ||
          !Order.validateCustomer(order.customer),
      } as Step,
    ],
  } as StepperProp;

  const stepDetails = [
    {
      title: t("create.title"),
      description: t("create.customer_selection.step_description"),
    },
    {
      title: t("create.route_selection.title"),
      description: t("create.route_selection.step_description"),
    },
    {
      title: t("create.product_management.title"),
      description: t("create.product_management.step_description"),
    },
    {
      title: t("create.driver_selection.title"),
      description: t("create.driver_selection.step_description"),
    },
  ];

  return (
    <div className="relative pb-20">
      <div className="flex flex-col-reverse sm:flex-row items-start sm:items-center sm:justify-between md:pb-8">
        <div className="w-full sm:w-1/2">
          <p className="lgb-title pb-2 text-xl">
            {stepDetails[currentStep].title}
          </p>
          <p className="text-lgb-grey-600 text-base dark:text-lgb-grey-200 pb-6 md:pb-0">
            {stepDetails[currentStep].description}
          </p>
        </div>
        <div className="w-full sm:w-1/2 px-4">
          <div className="md:max-w-full max-w-md sm:ml-auto">
            {/* Moved Stepper to top on smaller screens */}
            {customers && customers.length && (
              <Stepper
                stepClickEvent={(step) => handleStepChange(step)}
                {...createOrderSteps}
              />
            )}
          </div>
        </div>
      </div>

      {/* Conditionally render step-component based on the currentStep state */}
      {currentStep === CreateOrderStepsEnum.CUSTOMER_SELECTION && (
        <div className={transition}>
          <CustomerSelection
            selectedCustomer={order.customer as Customer}
            update={(c) => {
              set("customer", c);
              set("customerId", c.id);
            }}
            validationFailed={stepValidatedFailed}
          ></CustomerSelection>
        </div>
      )}
      {currentStep === CreateOrderStepsEnum.ROUTE_SELECTION && (
        <div className={transition}>
          <RouteSelection
            ref={routeSelectionRef}
            route={[...order.route]}
            update={(r) => {
              set("route", r);
            }}
            validationFailed={stepValidatedFailed}
          ></RouteSelection>
        </div>
      )}
      {currentStep === CreateOrderStepsEnum.PRODUCT_MANAGEMENT && (
        <div className={transition}>
          <ProductManagement
            ref={productSelectionRef}
            route={order.route}
            orderItems={order.cargo as OrderProduct[]}
            validationFailed={stepValidatedFailed}
            update={(p) => {
              set("cargo", p);
            }}
          ></ProductManagement>
        </div>
      )}
      {currentStep === CreateOrderStepsEnum.DRIVER_SELECTION && (
        <div className={transition}>
          <DriverSelection
            order={order}
            emitOnClick
            update={(id) => set("driverId", id)}
            comment={order.comment}
            validationFailed={stepValidatedFailed}
            updateComment={(c) => set("comment", c)}
          ></DriverSelection>
        </div>
      )}

      <div className="fixed bottom-0 left-0 w-full">
        <div className="flex items-center py-4 pr-8 justify-end bg-white border-t border-gray-200 dark:bg-darkBlue dark:border-gray-700">
          {currentStep !== CreateOrderStepsEnum.CUSTOMER_SELECTION && (
            <Button
              className="mr-5"
              color="light"
              onClick={() =>
                handleStepChange(currentStep > 0 ? currentStep - 1 : 0)
              }
            >
              <HiOutlineArrowLeft className="mr-2 h-5 w-5" />
              {t("orders:create.back_button")}
            </Button>
          )}
          {currentStep !== CreateOrderStepsEnum.DRIVER_SELECTION && (
            <Button
              color="mainGreen"
              onClick={() =>
                handleStepChange(currentStep < 4 ? currentStep + 1 : 4)
              }
            >
              {t("orders:create.proceed_button")}
              <HiOutlineArrowRight className="ml-2 h-5 w-5" />
            </Button>
          )}
          {currentStep === CreateOrderStepsEnum.DRIVER_SELECTION && (
            <Button
              color="mainGreen"
              disabled={!order.driverId}
              onClick={() => {
                setShowSummaryDialog(true);
              }}
            >
              {t("create.summary_and_complete_button")}
              <HiCheck className="ml-2 h-5 w-5" />
            </Button>
          )}
        </div>
      </div>
      {SummaryAndCompleteDialog}
    </div>
  );
};

export default CreateOrder;
