/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useEffect, useMemo } from "react";
import {
  PageLayout,
  TableSkeleton,
  StandardTable,
  DialogForm,
  CompanyModal,
} from "../../components";
import { HiTrash, HiPencilAlt } from "react-icons/hi";
import { useWorkspace } from "../../context/WorkspaceContext";
import { useCache } from "../../context/CacheContext";
import { useCompanies, useDrivers, useVehicles } from "../../hooks";
import { useTranslation } from "react-i18next";
import { ITableRow, ICompany } from "../../types";
import { Company } from "../../models";
import { EmptyState } from "../../components";
import { emptyCompanyIllustration } from "../../components/atoms/Icons/illustrations";
import { CompanyList } from "../../components/organisms/Company";

export const CompaniesPage: FC = function () {
  const [isUpsertModalOpen, openInsertModal] = useState(false);
  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const [companyData, setCompanyData] = useState(Company.default());
  const [isLoading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [splitIsOpen, setSplitIsOpen] = useState(false);

  const { activeWorkspace } = useWorkspace();
  const { updateCacheKey } = useCache();
  const companiesQuery = useCompanies(activeWorkspace?.workspaceId ?? "");
  const vehiclesQuery = useVehicles(activeWorkspace?.workspaceId ?? "");
  const driversQuery = useDrivers(activeWorkspace?.workspaceId ?? "");
  const { t } = useTranslation(["common", "company"]);

  const isQueryLoading = useMemo(
    () =>
      driversQuery.isLoading ||
      driversQuery.isRefetching ||
      companiesQuery.isLoading ||
      companiesQuery.isRefetching ||
      vehiclesQuery.isLoading ||
      vehiclesQuery.isRefetching,
    [driversQuery, companiesQuery, vehiclesQuery],
  );

  const companies = useMemo(() => companiesQuery.data ?? [], [companiesQuery]);
  const drivers = useMemo(() => driversQuery.data ?? [], [driversQuery]);
  const vehicles = useMemo(() => vehiclesQuery.data ?? [], [vehiclesQuery]);

  useEffect(() => {
    if (!isQueryLoading) {
      setLoading(false);
      setTableRows(
        companies.map((company) => {
          return {
            id: company.id,
            cells: [
              {
                id: "name",
                children: company.name,
                showOnSmallScreen: true,
              },
              {
                id: "organizationNumber",
                children: company.organizationNumber,
              },
              {
                id: "email",
                children: company.contactEmail,
              },
            ],
            actions: [
              {
                id: "edit",
                icon: <HiPencilAlt />,
                onActionClick: () => {
                  setCompanyData(company);
                  openInsertModal(true);
                },
              },
              {
                id: "delete",
                icon: <HiTrash />,
                color: "failure",
                onActionClick: () => {
                  setCompanyData(company);
                  openDeleteModal(true);
                },
              },
            ],
          };
        }),
      );
    }
  }, [isQueryLoading, companies]);

  return (
    <PageLayout>
      {isLoading ? (
        <TableSkeleton />
      ) : tableRows.length === 0 ? (
        // Render empty state when there are no table rows
        <EmptyState
          icon={emptyCompanyIllustration}
          title={t("company:empty_state.title")}
          description={t("company:empty_state.description")}
          buttonText={t("company:empty_state.button_text")}
          buttonClick={() => {
            setCompanyData(Company.default());
            openInsertModal(true);
          }}
        />
      ) : (
        <CompanyList
          drivers={drivers}
          vehicles={vehicles}
          companies={companies}
          isLoading={isQueryLoading}
          isOpen={setSplitIsOpen}
          createOrUpdate={(formData: Company) => {
            setCompanyData(formData);
            openInsertModal(true);
          }}
        />
      )}

      <CompanyModal
        data={companyData}
        isShowing={isUpsertModalOpen}
        onConfirm={(formData: ICompany) => {
          if (Company.isNew(companyData))
            Company.create(activeWorkspace?.workspaceId ?? "", formData);
          else Company.update(companyData, formData);
          openInsertModal(false);
          updateCacheKey();
        }}
        onCancel={() => openInsertModal(false)}
      />

      <DialogForm
        title={t("company:delete_company") + " " + companyData.name + "?"}
        show={isDeleteModalOpen}
        toggleModal={() => openDeleteModal(false)}
        showHeader={false}
        dismissible
        buttonConfirmColor="failure"
        confirmButton={() => {
          Company.delete(companyData);
          openDeleteModal(false);
          updateCacheKey();
        }}
        buttonConfirmText={t("common:confirm")}
        buttonConfirmPrependIcon={<HiTrash className="mr-2 h-4 w-4" />}
        buttonCloseText={t("common:close")}
      />
    </PageLayout>
  );
};
