import type { FC } from "react";
import { useState, useMemo, useEffect } from "react";
import {
  PageLayout,
  TableSkeleton,
  EmptyState,
  CustomerModal,
} from "../components";

import { useWorkspace } from "../context/WorkspaceContext";
import { useCache } from "../context/CacheContext";
import { useCustomers } from "../hooks";
import { useTranslation } from "react-i18next";

import { Customer } from "../models";
import { emptyCustomerIllustration } from "../components/atoms/Icons/illustrations";
import { ICustomer } from "../types";
import { CustomersList } from "../components/organisms/Customer/CustomersList";

const CustomersPage: FC = function () {
  const [isInsertModalOpen, setInsertModalOpen] = useState(false);
  const [customerData, setCustomerData] = useState<Customer>(
    Customer.default(),
  );
  const [isLoading, setLoading] = useState(true);
  // State controlling the right pane (split). Mirrors your "Locations" approach.
  const [splitIsOpen, setSplitIsOpen] = useState(false);

  const { activeWorkspace } = useWorkspace();
  const { updateCacheKey } = useCache();
  const { t } = useTranslation(["common", "customer"]);

  // Fetch customers
  const query = useCustomers(activeWorkspace?.workspaceId ?? "");
  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query],
  );
  const customers = useMemo(() => query.data ?? [], [query]);

  // Handle customer creation or update
  const handleCreateOrUpdate = async (formData: Partial<ICustomer>) => {
    try {
      const workspaceId = activeWorkspace?.workspaceId;
      if (!workspaceId) {
        throw new Error("Workspace ID is undefined.");
      }

      if (Customer.isNew(customerData)) {
        // Create a new customer
        await Customer.create(workspaceId, {
          ...formData,
          workspaceId, // Ensure workspaceId is always a string
        } as Omit<ICustomer, "id" | "createdAt">);
      } else {
        // Update an existing customer
        await Customer.update(customerData, formData);
      }

      setInsertModalOpen(false);
      updateCacheKey(); // Refresh cache
    } catch (error) {
      console.error("Error creating/updating customer:", error);
    }
  };

  // Mark loading false once the query finishes
  useEffect(() => {
    if (!isQueryLoading) {
      setLoading(false);
    }
  }, [isQueryLoading]);

  return (
    <PageLayout>
      {/* Main content */}
      {isLoading ? (
        <TableSkeleton />
      ) : customers.length === 0 ? (
        <EmptyState
          icon={emptyCustomerIllustration}
          title={t("customer:empty_state.title")}
          description={t("customer:empty_state.description")}
          buttonText={t("customer:empty_state.button_text")}
          buttonClick={() => {
            setCustomerData(Customer.default());
            setInsertModalOpen(true);
          }}
        />
      ) : (
        <CustomersList
          customers={customers}
          isLoading={isQueryLoading}
          isOpen={setSplitIsOpen}
          createOrUpdate={(formData: Customer) => {
            setCustomerData(formData);
            setInsertModalOpen(true);
          }}
        />
      )}

      {/* Customer Modal */}
      <CustomerModal
        data={customerData}
        isShowing={isInsertModalOpen}
        onConfirm={handleCreateOrUpdate}
        onCancel={() => setInsertModalOpen(false)}
      />
    </PageLayout>
  );
};

export default CustomersPage;
