/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useEffect } from "react";
import { HiOutlinePlus } from "react-icons/hi";
import {
  StandardForm,
  Dialog,
  StyledTextInput,
  StyledTextArea,
  CustomSelect,
} from "../..";
import { IProduct, IParameter, InputValidationResult } from "../../../types";
import { useTranslation } from "react-i18next";
import { Product } from "../../../models";
import {
  validateHasValue,
  validateNumberBetween,
  validateStringLength,
  hasValidationError,
} from "../../../helpers/validationHelper";
import { IPriceModel } from "../../../types/Product";

export interface ProductModalProp {
  data: IProduct;
  productTypes: IParameter[];
  isShowing: boolean;
  onConfirm: (data: IProduct) => void;
  onCancel: () => void;
}

export const ProductForm: FC<ProductModalProp> = function (
  props: ProductModalProp,
) {
  const { t } = useTranslation(["common", "inventory"]);
  const [isNew] = useState(Product.isNew(props.data));
  const [pricingModels, setPricingModels] = useState<IPriceModel[]>(
    props.data.pricing?.priceModel ?? [],
  );
  const [productType, setProductType] = useState<string[]>(
    props.data.pricing?.priceModel ?? [],
  );
  const [isValidated, setIsValidated] = useState(false);
  const [validations, setValidations] = useState([] as InputValidationResult[]);

  const getValidationResults = (product: IProduct) => {
    return [
      {
        id: "descriptionShort",
        show: isValidated,
        isInvalid:
          !validateHasValue(product.descriptionShort) ||
          !validateStringLength(product.descriptionShort ?? "", 2),
        errorMessage: t("common:errors.fields.not_empty"),
      },
      {
        id: "weight",
        show: isValidated,
        isInvalid:
          validateHasValue(product.weight.toString()) &&
          !validateNumberBetween(product.weight, 0, 10000),
        errorMessage: t("common:errors.fields.number"),
      },
      {
        id: "height",
        show: isValidated,
        isInvalid:
          validateHasValue(product.height.toString()) &&
          !validateNumberBetween(product.height, 0, 10000),
        errorMessage: t("common:errors.fields.number"),
      },
      {
        id: "width",
        show: isValidated,
        isInvalid:
          validateHasValue(product.width.toString()) &&
          !validateNumberBetween(product.width, 0, 10000),
        errorMessage: t("common:errors.fields.number"),
      },
      {
        id: "length",
        show: isValidated,
        isInvalid:
          validateHasValue(product.length.toString()) &&
          !validateNumberBetween(product.length, 0, 10000),
        errorMessage: t("common:errors.fields.number"),
      },
      {
        id: "productType",
        show: isValidated,
        isInvalid:
          product.productType === undefined || !product.productType.length,
        errorMessage: t("common:errors.fields.not_empty"),
      },
      {
        id: "pricing.priceModel",
        show: isValidated,
        isInvalid:
          product.pricing === undefined ||
          product.pricing.priceModel === undefined ||
          product.pricing.priceModel.length === 0,
        errorMessage: t("common:errors.fields.not_empty"),
      },
      {
        id: "pricing.pricePerCargoUnit",
        show: isValidated,
        isInvalid:
          product.pricing !== undefined &&
          product.pricing.priceModel !== undefined &&
          product.pricing.priceModel.includes(IPriceModel.FIXED) &&
          validateHasValue(product.pricing.pricePerCargoUnit?.toString()) &&
          !validateNumberBetween(
            product.pricing.pricePerCargoUnit ?? 0,
            1,
            10000,
          ),
        errorMessage: t("common:errors.fields.number"),
      },
      {
        id: "pricing.pricePerDistanceUnit",
        show: isValidated,
        isInvalid:
          product.pricing !== undefined &&
          product.pricing.priceModel !== undefined &&
          product.pricing.priceModel.includes(IPriceModel.DISTANCE) &&
          validateHasValue(product.pricing.pricePerDistanceUnit?.toString()) &&
          !validateNumberBetween(
            product.pricing.pricePerDistanceUnit ?? 0,
            1,
            10000,
          ),
        errorMessage: t("common:errors.fields.number"),
      },
      {
        id: "pricing.pricePerVolumeUnit",
        show: isValidated,
        isInvalid:
          product.pricing !== undefined &&
          product.pricing.priceModel !== undefined &&
          product.pricing.priceModel.includes(IPriceModel.VOLUME) &&
          validateHasValue(product.pricing.pricePerVolumeUnit?.toString()) &&
          !validateNumberBetween(
            product.pricing.pricePerVolumeUnit ?? 0,
            1,
            10000,
          ),
        errorMessage: t("common:errors.fields.number"),
      },
      {
        id: "pricing.pricePerWeightUnit",
        show: isValidated,
        isInvalid:
          product.pricing !== undefined &&
          product.pricing.priceModel !== undefined &&
          product.pricing.priceModel.includes(IPriceModel.WEIGHT) &&
          validateHasValue(product.pricing.pricePerWeightUnit?.toString()) &&
          !validateNumberBetween(
            product.pricing.pricePerWeightUnit ?? 0,
            1,
            10000,
          ),
        errorMessage: t("common:errors.fields.number"),
      },
    ];
  };

  const validateAndConfirm = (product: IProduct) => {
    let updatedProduct = { ...product };
    updatedProduct.productType = productType;
    if (
      updatedProduct.pricing !== undefined &&
      updatedProduct.pricing.priceModel !== undefined
    ) {
      updatedProduct.pricing.priceModel = pricingModels;
    }

    // Trigger validation
    const validations = getValidationResults(updatedProduct);
    setValidations(validations);
    // If valid form, we trigger the onConfirm
    if (!hasValidationError(validations)) props.onConfirm(updatedProduct);
  };

  useEffect(() => {
    setIsValidated(true);
  }, [setValidations, validations]);

  return (
    <StandardForm
      showHeader={true}
      description={t("modals:descriptions.new_product")}
      fields={[
        {
          id: "descriptionShort",
          label: t("inventory:product_name"),
          grid_style: "col-span-2",
          input: (
            <StyledTextInput
              id="descriptionShort"
              placeholder={t("inventory:product_description_example")}
              defaultValue={props.data.descriptionShort ?? ""}
              validation={validations.find(
                (validation) => validation.id === "descriptionShort",
              )}
            />
          ),
        },
        {
          id: "description",
          label: t("inventory:product_description"),
          grid_style: "col-span-2",
          input: (
            <StyledTextArea
              id="description"
              defaultValue={props.data.description ?? ""}
              placeholder={t("modals:placeholders.text_area")}
            />
          ),
        },
        {
          id: "weight",
          label: t("common:weight"),
          input: (
            <StyledTextInput
              id="weight"
              placeholder={t("common:placeholder.weight")}
              defaultValue={props.data.weight ?? ""}
              type="number"
              validation={validations.find(
                (validation) => validation.id === "weight",
              )}
            />
          ),
        },
        {
          id: "length",
          label: t("common:length"),
          input: (
            <StyledTextInput
              id="length"
              placeholder={t("common:placeholder.length")}
              defaultValue={props.data.length ?? ""}
              type="number"
              validation={validations.find(
                (validation) => validation.id === "length",
              )}
            />
          ),
        },
        {
          id: "height",
          label: t("common:height"),
          input: (
            <StyledTextInput
              id="height"
              placeholder={t("common:placeholder.height")}
              defaultValue={props.data.height ?? ""}
              type="number"
              validation={validations.find(
                (validation) => validation.id === "height",
              )}
            />
          ),
        },
        {
          id: "width",
          label: t("common:width"),
          input: (
            <StyledTextInput
              id="width"
              placeholder={t("common:placeholder.width")}
              defaultValue={props.data.width ?? ""}
              type="number"
              validation={validations.find(
                (validation) => validation.id === "width",
              )}
            />
          ),
        },
        {
          id: "productType",
          label: t("common:productType_label"),
          tooltip: t("common:productType_tooltip"),
          input: (
            <CustomSelect
              type="radio"
              defaultValue={props.data.productType ?? productType ?? ""}
              label={t("modals:placeholders.product_type")}
              onSelectChanged={(value) => {
                setProductType([value] as string[]);
              }}
              items={props.productTypes.map((type) => ({
                key: type.id,
                label: type.code,
                value: type.id,
              }))}
              validation={validations.find(
                (validation) => validation.id === "productType",
              )}
            />
          ),
        },
        {
          id: "pricing.priceModel",
          label: t("common:priceModel_label"),
          tooltip: t("common:priceModel_tooltip"),
          input: (
            <CustomSelect
              type="checkbox"
              defaultValue={
                props.data.pricing?.priceModel ?? pricingModels ?? []
              } // Ensure default value is correctly set
              label={t("modals:placeholders.price_model")}
              onSelectChanged={(values) => {
                setPricingModels(values as IPriceModel[]);
              }}
              items={[
                {
                  key: IPriceModel.FIXED,
                  label: t("common:price_model.fixed.title"),
                  value: IPriceModel.FIXED,
                  description: t("common:price_model.fixed.description"),
                },
                {
                  key: IPriceModel.VOLUME,
                  label: t("common:price_model.volume.title"),
                  value: IPriceModel.VOLUME,
                  description: t("common:price_model.volume.description"),
                },
                {
                  key: IPriceModel.WEIGHT,
                  label: t("common:price_model.weight.title"),
                  value: IPriceModel.WEIGHT,
                  description: t("common:price_model.weight.description"),
                },
                {
                  key: IPriceModel.DISTANCE,
                  label: t("common:price_model.distance.title"),
                  value: IPriceModel.DISTANCE,
                  description: t("common:price_model.distance.description"),
                },
              ]}
              validation={validations.find(
                (validation) => validation.id === "pricing.priceModel",
              )}
            />
          ),
        },
        {
          id: "pricing.pricePerCargoUnit",
          label: t("common:price_model.fixed.title"),
          hidden: !pricingModels?.includes(IPriceModel.FIXED),
          input: (
            <StyledTextInput
              id="pricing.pricePerCargoUnit"
              placeholder={t("common:price_model.fixed.placeholder")}
              defaultValue={props.data.pricing?.pricePerCargoUnit ?? ""}
              type="number"
              validation={validations.find(
                (validation) => validation.id === "pricing.pricePerCargoUnit",
              )}
            />
          ),
        },
        {
          id: "pricing.pricePerDistanceUnit",
          label: t("common:price_model.distance.title"),
          hidden: !pricingModels?.includes(IPriceModel.DISTANCE),
          input: (
            <StyledTextInput
              id="pricing.pricePerDistanceUnit"
              placeholder={t("common:price_model.distance.placeholder")}
              defaultValue={props.data.pricing?.pricePerDistanceUnit ?? ""}
              type="number"
              validation={validations.find(
                (validation) =>
                  validation.id === "pricing.pricePerDistanceUnit",
              )}
            />
          ),
        },
        {
          id: "pricing.pricePerVolumeUnit",
          label: t("common:price_model.volume.title"),
          hidden: !pricingModels?.includes(IPriceModel.VOLUME),
          input: (
            <StyledTextInput
              id="pricing.pricePerVolumeUnit"
              placeholder={t("common:price_model.volume.placeholder")}
              defaultValue={props.data.pricing?.pricePerVolumeUnit ?? ""}
              type="number"
              validation={validations.find(
                (validation) => validation.id === "pricing.pricePerVolumeUnit",
              )}
            />
          ),
        },
        {
          id: "pricing.pricePerWeightUnit",
          label: t("common:price_model.weight.title"),
          hidden: !pricingModels?.includes(IPriceModel.WEIGHT),
          input: (
            <StyledTextInput
              id="pricing.pricePerWeightUnit"
              placeholder={t("common:price_model.weight.placeholder")}
              defaultValue={props.data.pricing?.pricePerWeightUnit ?? ""}
              type="number"
              validation={validations.find(
                (validation) => validation.id === "pricing.pricePerWeightUnit",
              )}
            />
          ),
        },
      ]}
      onConfirm={validateAndConfirm}
      onCancel={props.onCancel}
      buttonConfirmText={t("modals:button_texts.product")}
      buttonConfirmPrependIcon={
        isNew ? <HiOutlinePlus className="mr-2 h-4 w-4" /> : null
      }
      buttonCloseText={t("common:close")}
    />
  );
};

export const ProductModal: FC<ProductModalProp> = function (
  props: ProductModalProp,
) {
  const { t } = useTranslation(["common", "inventory", "modals"]);

  return (
    <Dialog
      title={
        Product.isNew(props.data)
          ? t("inventory:new_product")
          : t("inventory:update_product")
      }
      content={<ProductForm {...props} />}
      show={props.isShowing}
      toggleModal={props.onCancel}
      hideButtons={true}
    />
  );
};
