/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogForm, LocationModal, SplitTable } from "../..";
import { Location } from "../../../models";
import { ITableRow } from "../../../types";
import { DropdownButton } from "../../atoms/Button/DropdownButton";
import { HiOutlineChevronDown, HiChevronRight } from "react-icons/hi";
import { SplitTableRef } from "../../molecules/SplitTable/SplitTable";
import { HiTrash, HiPencilAlt } from "react-icons/hi";
import { LocationDetails } from "../../molecules/LocationDetails";
import { useCache } from "../../../context/CacheContext";
import { useLocation, useNavigate } from "react-router-dom";

interface LocationsListProp {
  locations: Location[];
  isLoading: boolean;
  isOpen: (val: boolean) => void;
}

export const LocationsList: FC<LocationsListProp> = function (
  props: LocationsListProp,
) {
  const { t } = useTranslation(["common", "orders", "validation", "location"]);
  const [openedLocation, setOpenedLocation] = useState<Location | undefined>(
    undefined,
  );
  const [locationData, setLocationData] = useState<Location>(
    Location.defaultLocation(),
  );
  const [isInsertModalOpen, openInsertModal] = useState(false);
  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [hideColumns, setHideColumns] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState<Location[]>();
  const location = useLocation();
  const navigate = useNavigate();
  const { updateCacheKey } = useCache();
  const splitTableRef = useRef<SplitTableRef>(null);

  // Function to open/close the details view and set URL parameters
  const updateOpenedLocation = (loc: Location | undefined) => {
    setOpenedLocation(loc);
    if (loc) {
      setHideColumns(true);
      props.isOpen(true);
      // Construct location parameter from addressLine, postCode and city
      const locationParam = `${loc.addressLine
        ?.toLowerCase()
        .replace(/\s+/g, "-")}-${loc.postCode}-${loc.city
        ?.toLowerCase()
        .replace(/\s+/g, "-")}`;
      // Update the URL
      navigate(`/resources/locations?location=${locationParam}`, {
        replace: true,
      });
      splitTableRef.current?.setSplit(true);
    } else {
      setHideColumns(false);
      props.isOpen(false);
      navigate(`/resources/locations`, { replace: true });
      splitTableRef.current?.setSplit(false);
    }
  };

  // Check if there's a ?location= param in the URL and open that location if found
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const locationParam = params.get("location");
    if (locationParam) {
      // Split the param back into [address, postcode, city]
      const parts = locationParam.split("-");
      if (parts.length >= 3) {
        const postcode = parts[parts.length - 2]; // second last
        const city = parts[parts.length - 1]; // last
        const address = parts.slice(0, parts.length - 2).join(" ");
        // Find matching location
        const matchingLocation = props.locations.find(
          (loc) =>
            loc.addressLine?.toLowerCase().replace(/\s+/g, "-") ===
              address.replace(/\s+/g, "-") &&
            loc.postCode === postcode &&
            loc.city?.toLowerCase().replace(/\s+/g, "-") === city,
        );
        if (matchingLocation) {
          updateOpenedLocation(matchingLocation);
        } else {
          // If no match, ensure we don't show a stale param
          navigate(`/resources/locations`, { replace: true });
        }
      } else {
        // If invalid param format, clear it
        navigate(`/resources/locations`, { replace: true });
      }
    }
  }, [location.search, props.locations]);

  useEffect(() => {
    if (!props.isLoading && props.locations) {
      if (searchText.length) {
        setSearchResults(
          props.locations.filter((x) =>
            x.displayName.toLowerCase().includes(searchText.toLowerCase()),
          ),
        );
      } else {
        setSearchResults(props.locations || []);
      }
    }
  }, [searchText, props.locations, props.isLoading]);

  useEffect(() => {
    if (!props.isLoading && searchResults) {
      setTableRows(
        searchResults.map((loc) => {
          return {
            id: loc.id,
            isActive: loc.id === openedLocation?.id,
            onRowClickData: loc,
            cells: [
              {
                id: "displayName",
                children: loc.displayName,
                showOnSmallScreen: true,
              },
              {
                id: "addressLine",
                children: loc.addressLine,
                hidden: hideColumns,
              },
              {
                id: "city",
                children: loc.city,
                hidden: hideColumns,
              },
            ],
            actions: !openedLocation
              ? [
                  {
                    id: "edit",
                    icon: <HiPencilAlt />,
                    onActionClick: (e: any) => {
                      e.stopPropagation();
                      setLocationData(loc);
                      openInsertModal(true);
                    },
                  },
                  {
                    id: "delete",
                    icon: <HiTrash />,
                    color: "failure",
                    onActionClick: (e: any) => {
                      e.stopPropagation();
                      setLocationData(loc);
                      openDeleteModal(true);
                    },
                  },
                ]
              : [],
          };
        }),
      );
    }
  }, [props.isLoading, searchResults, t, openedLocation, hideColumns]);

  return (
    <>
      <SplitTable
        ref={splitTableRef}
        searchable
        hasActions
        isLoading={props.isLoading}
        tableRows={tableRows}
        onRowClick={(loc: Location | undefined) => updateOpenedLocation(loc)}
        updateTable={(val) => setSearchText(val)}
        content={
          <LocationDetails
            location={openedLocation}
            edit={() => {
              if (openedLocation) setLocationData(openedLocation);
              openInsertModal(true);
            }}
            delete={() => {
              if (openedLocation) setLocationData(openedLocation);
              openDeleteModal(true);
            }}
          />
        }
        topRightContent={
          <div className="flex items-center">
            {openedLocation && (
              <DropdownButton
                placement="bottom-end"
                buttonText={t("location:actions.buttonText")}
                buttonAppendIcon={<HiOutlineChevronDown className="ml-2" />}
                items={[
                  {
                    text: (
                      <p className="p-4 w-48">{t("location:actions.edit")}</p>
                    ),
                    onClick: () => {
                      setLocationData(openedLocation);
                      openInsertModal(true);
                    },
                  },
                  {
                    text: (
                      <p className="p-4 w-48 text-lgb-red-300 dark:text-lgb-red-200 hover:text-lgb-red-200 hover:dark:text-lgb-red-300">
                        {t("location:actions.delete.buttonText")}
                      </p>
                    ),
                    onClick: () => {
                      setLocationData(openedLocation);
                      openDeleteModal(true);
                    },
                  },
                ]}
              />
            )}
          </div>
        }
        tableHeaders={[
          {
            id: "displayName",
            children: t("common:customer"),
            showOnSmallScreen: true,
          },
          {
            id: "addressLine",
            children: t("common:address_line"),
            hidden: hideColumns,
          },
          {
            id: "city",
            children: t("common:city"),
            hidden: hideColumns,
          },
        ]}
      />

      <div>
        <LocationModal
          data={locationData}
          isShowing={isInsertModalOpen}
          onConfirm={(loc: Location) => {
            if (locationData) {
              if (Location.isNew(locationData)) Location.create(loc);
              else Location.update(locationData, loc);
            }
            openInsertModal(false);
            updateCacheKey();
          }}
          onCancel={() => openInsertModal(false)}
        />

        <DialogForm
          title={
            t("location:actions.delete.dialogTitle") +
            " " +
            locationData?.displayName +
            "?"
          }
          show={isDeleteModalOpen}
          toggleModal={() => openDeleteModal(false)}
          showHeader={false}
          buttonConfirmColor="failure"
          confirmButton={() => {
            if (locationData) {
              Location.delete(locationData);
            }
            openDeleteModal(false);
            updateCacheKey();
            // If we are currently viewing this location, close it
            if (openedLocation?.id === locationData.id) {
              updateOpenedLocation(undefined);
            }
          }}
          buttonConfirmText={t("common:confirm")}
          buttonConfirmPrependIcon={<HiTrash className="mr-2 h-4 w-4" />}
          buttonCloseText={t("common:close")}
        />
      </div>
    </>
  );
};
