/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useMemo, useEffect } from "react";
import { HiTrash, HiPencilAlt } from "react-icons/hi";
import {
  PageLayout,
  TableSkeleton,
  ProductModal,
  DialogForm,
} from "../../components";
import { useWorkspace } from "../../context/WorkspaceContext";
import { useCache } from "../../context/CacheContext";
import { useProducts, useParameters } from "../../hooks";
import { useTranslation } from "react-i18next";
import { ParameterType, ITableRow, IProduct } from "../../types";
import { Parameter, Product } from "../../models";
import { emptyProductIllustration } from "../../components/atoms/Icons/illustrations";
import { EmptyState } from "../../components";
import { ProductsList } from "../../components/organisms/Product";

export const ProductsPage: FC = function () {
  const [isLoading, setLoading] = useState(true);
  const [splitIsOpen, setSplitIsOpen] = useState(false);
  const { updateCacheKey } = useCache();

  const [isUpsertModalOpen, openUpsertModal] = useState(false);
  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const { activeWorkspace } = useWorkspace();
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [productData, setProductData] = useState(Product.default());
  const [productCategories, setProductCategories] = useState([] as Parameter[]);

  const categoriesQuery = useParameters(
    activeWorkspace?.workspaceId ?? "",
    ParameterType.ProductType,
  );
  const query = useProducts(activeWorkspace?.workspaceId ?? "");
  const { t } = useTranslation(["common", "inventory", "products"]);

  const isQueryLoading = useMemo(
    () =>
      query.isLoading ||
      query.isRefetching ||
      categoriesQuery.isLoading ||
      categoriesQuery.isRefetching,
    [query, categoriesQuery],
  );

  const products = useMemo(() => query.data ?? [], [query]);

  const categories = useMemo(
    () => categoriesQuery.data ?? [],
    [categoriesQuery],
  );

  useEffect(() => {
    if (!isQueryLoading) {
      setProductCategories(categories);
    }
  }, [isQueryLoading, categories]);

  useEffect(() => {
    if (!isQueryLoading) {
      setLoading(false);
      setTableRows(
        products.map((product) => {
          return {
            id: product.id,
            cells: [
              {
                id: "descriptionShort",
                children: product.descriptionShort,
                showOnSmallScreen: true,
              },
            ],
            actions: [
              {
                id: "edit",
                icon: <HiPencilAlt />,
                onActionClick: () => {
                  setProductData(product);
                  openUpsertModal(true);
                },
              },
              {
                id: "delete",
                icon: <HiTrash />,
                color: "failure",
                onActionClick: () => {
                  setProductData(product);
                  openDeleteModal(true);
                },
              },
            ],
          };
        }),
      );
    }
  }, [isQueryLoading, products]);

  return (
    <PageLayout>
      {isLoading ? (
        <TableSkeleton />
      ) : tableRows.length === 0 ? (
        // Render empty state when there are no table rows
        <EmptyState
          icon={emptyProductIllustration}
          title={t("products:empty_state.title")}
          description={t("products:empty_state.description")}
          buttonText={t("products:empty_state.button_text")}
          buttonClick={() => {
            setProductData(Product.default());
            openUpsertModal(true);
          }}
        />
      ) : (
        <ProductsList
          products={products}
          isLoading={isQueryLoading}
          isOpen={setSplitIsOpen}
          createOrUpdate={(formData: Product) => {
            setProductData(formData);
            openUpsertModal(true);
          }}
        />
      )}

      <ProductModal
        data={productData}
        productTypes={productCategories}
        isShowing={isUpsertModalOpen}
        onConfirm={(formData: IProduct) => {
          if (Product.isNew(productData))
            Product.create(activeWorkspace?.workspaceId ?? "", formData);
          else Product.update(productData, formData);
          openUpsertModal(false);
          updateCacheKey();
          setLoading(true);
        }}
        onCancel={() => openUpsertModal(false)}
      />

      <DialogForm
        title={
          t("inventory:delete_product") +
          " " +
          productData.descriptionShort +
          "?"
        }
        show={isDeleteModalOpen}
        toggleModal={() => openDeleteModal(false)}
        showHeader={false}
        buttonConfirmColor="failure"
        dismissible
        confirmButton={() => {
          Product.delete(productData);
          openDeleteModal(false);
          updateCacheKey();
          setLoading(true);
        }}
        buttonConfirmText={t("common:confirm")}
        buttonConfirmPrependIcon={<HiTrash className="mr-2 h-4 w-4" />}
        buttonCloseText={t("common:close")}
      />
    </PageLayout>
  );
};
