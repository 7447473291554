import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { DialogForm, SplitTable } from "../..";
import { SplitTableRef } from "../../molecules/SplitTable/SplitTable";
import { DropdownButton } from "../../atoms/Button/DropdownButton";
import {
  HiOutlineChevronDown,
  HiTrash,
  HiPencilAlt,
  HiOutlinePlus,
} from "react-icons/hi";

import { Company, Driver, Vehicle } from "../../../models";
import { useCache } from "../../../context/CacheContext";
import { Button } from "flowbite-react";
import { DriverDetails } from "./DriverDetails";

interface DriversListProps {
  drivers: Driver[];
  companies: Company[];
  vehicles: Vehicle[];
  isLoading: boolean;
  isOpen: (val: boolean) => void; // toggles the right pane
  createOrUpdate: (formData: Driver) => void;
}

export const DriversList: FC<DriversListProps> = ({
  drivers,
  companies,
  vehicles,
  isLoading,
  isOpen,
  createOrUpdate,
}) => {
  const { t } = useTranslation(["common", "driver"]);
  const navigate = useNavigate();
  const { updateCacheKey } = useCache();

  const [openedDriver, setOpenedDriver] = useState<Driver | undefined>();
  const [company, setCompany] = useState<Company | undefined>();
  const [vehicle, setVehicles] = useState<Vehicle | undefined>();
  const [driverData, setDriverData] = useState<Driver>(Driver.default());
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [filteredDrivers, setFilteredDrivers] = useState<Driver[]>(drivers);

  const splitTableRef = useRef<SplitTableRef>(null);

  // Update opened driver
  const updateOpenedDriver = (driver: Driver | undefined) => {
    setOpenedDriver(driver);
    setCompany(companies.find((c) => c.id === driver?.companyId));
    setVehicles(vehicles.find((v) => v.id === driver?.vehicleId));

    if (driver) {
      isOpen(true);
      const driverName = driver.firstName + " " + driver.lastName || "unknown";
      const paramSafeName = driverName.toLowerCase().replace(/\s+/g, "-");
      navigate(`/resources/drivers?driver=${paramSafeName}`, {
        replace: true,
      });
      splitTableRef.current?.setSplit(true);
    } else {
      isOpen(false);
      navigate(`/resources/drivers`, { replace: true });
      splitTableRef.current?.setSplit(false);
    }
  };

  // Filter drivers based on search text
  useEffect(() => {
    if (searchText.trim().length > 0) {
      const lowerCaseSearchText = searchText.toLowerCase();
      setFilteredDrivers(
        drivers.filter((driver) =>
          (driver.firstName + " " + driver.lastName)
            .toLowerCase()
            .includes(lowerCaseSearchText),
        ),
      );
    } else {
      setFilteredDrivers(drivers);
    }
  }, [searchText, drivers]);

  // Table rows
  const tableRows = filteredDrivers.map((driver) => ({
    id: driver.id,
    isActive: driver.id === openedDriver?.id,
    onRowClickData: driver,
    cells: [
      {
        id: "name",
        children:
          driver.firstName + " " + driver.lastName ||
          t("driver:unknown_driver"),
      },
      {
        id: "email",
        children: driver.email || "",
      },
    ],
    actions: !openedDriver
      ? [
          {
            id: "edit",
            icon: <HiPencilAlt />,
            onActionClick: (e: React.MouseEvent) => {
              e.stopPropagation();
              createOrUpdate(driver);
            },
          },
          {
            id: "delete",
            icon: <HiTrash />,
            color: "failure",
            onActionClick: (e: React.MouseEvent) => {
              e.stopPropagation();
              setDriverData(driver);
              setDeleteModalOpen(true);
            },
          },
        ]
      : [],
  }));

  // Handle vehicle deletion
  const handleDelete = async () => {
    try {
      await Driver.delete(driverData);
      setDeleteModalOpen(false);
      updateCacheKey();

      if (openedDriver?.id === driverData.id) {
        updateOpenedDriver(undefined);
      }
    } catch (error) {
      console.error("Error deleting driver:", error);
    }
  };

  return (
    <>
      {/* Header */}
      <div className="flex justify-between items-center mb-4 p-4">
        <div>
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            {t("driver:title")}
          </h1>
          <p className="text-lgb-grey-600 text-base pt-2 pb-4 dark:text-lgb-grey-200">
            {t("driver:description")}
          </p>
        </div>
        <Button
          color="light"
          onClick={() => {
            createOrUpdate(Driver.default());
          }}
          className="flex items-center"
        >
          <HiOutlinePlus className="mr-2 h-5 w-5" />
          {t("driver:new_driver")}
        </Button>
      </div>

      {/* SplitTable */}
      <SplitTable
        ref={splitTableRef}
        searchable
        hasActions
        isLoading={isLoading}
        tableRows={tableRows}
        tableHeaders={[
          { id: "displayName", children: t("common:drivers") },
          { id: "description", children: t("common:description") },
        ]}
        onRowClick={(driver: Driver | undefined) => updateOpenedDriver(driver)}
        updateTable={(text) => setSearchText(text)}
        topRightContent={
          <div className="flex items-center">
            {openedDriver && (
              <DropdownButton
                placement="bottom-end"
                buttonText={t("driver:actions.buttonText")}
                buttonAppendIcon={<HiOutlineChevronDown className="ml-2" />}
                items={[
                  {
                    text: (
                      <p className="p-4 w-48">{t("driver:actions.edit")}</p>
                    ),
                    onClick: () => {
                      createOrUpdate(openedDriver);
                    },
                  },
                  {
                    text: (
                      <p className="p-4 w-48 text-lgb-red-300 dark:text-lgb-red-200 hover:text-lgb-red-200 hover:dark:text-lgb-red-300">
                        {t("driver:actions.delete")}
                      </p>
                    ),
                    onClick: () => {
                      setDriverData(openedDriver);
                      setDeleteModalOpen(true);
                    },
                  },
                ]}
              />
            )}
          </div>
        }
        content={
          openedDriver ? (
            <DriverDetails
              driver={openedDriver}
              company={company}
              vehicle={vehicle}
            />
          ) : (
            <div className="p-4 text-gray-600">
              {t("driver:split.no_driver_selected")}
            </div>
          )
        }
      />

      {/* Delete Confirmation Dialog */}
      <DialogForm
        title={`${t("driver:actions.delete_dialogTitle")} ${driverData.firstName ?? ""}?`}
        dismissible
        show={isDeleteModalOpen}
        toggleModal={() => setDeleteModalOpen(false)}
        buttonConfirmColor="failure"
        confirmButton={handleDelete}
        buttonConfirmText={t("common:confirm")}
        buttonCloseText={t("common:close")}
      />
    </>
  );
};
