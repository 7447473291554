import { Timestamp } from ".";

export interface IProduct {
  id: string;
  workspaceId: string;
  descriptionShort: string;
  description: string;
  imgUrls?: string[];
  productType: string[];
  pricing?: IProductPricing;
  weight: number;
  length: number;
  height: number;
  width: number;
  productId?: string;
  createdAt?: Timestamp;
}

export enum IPriceModel {
  FIXED = "Fixed",
  VOLUME = "Volume",
  WEIGHT = "Weight",
  DISTANCE = "Distance",
}

export interface IProductPricing {
  priceModel: IPriceModel[];
  pricePerCargoUnit?: number;
  pricePerDistanceUnit?: number;
  pricePerVolumeUnit?: number;
  pricePerWeightUnit?: number;
}

export interface IProductDeliveryInformationValidated {
  deliveryInformation?: boolean;
  quantity?: boolean;
}

export interface IProductPropertyValidated {
  descriptionShort?: boolean;
  description?: boolean;
  weight?: boolean;
  length?: boolean;
  height?: boolean;
  width?: boolean;
  price?: boolean;
}
