// src/components/molecules/CustomerDetails/CustomerDetails.tsx

import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import { FaRegCopy } from "react-icons/fa";
import { SimpleList } from "../../molecules/SimpleList";
import { Company, Vehicle } from "../../../models";

interface VehicleDetailsProps {
  vehicle: Vehicle | undefined;
  company: Company | undefined;
}

export const VehicleDetails: FC<VehicleDetailsProps> = ({
  vehicle,
  company,
}) => {
  const { t } = useTranslation(["common", "vehicles"]);
  const [hasCopied, setHasCopied] = useState(false);

  // If no product is selected, show a placeholder
  if (!vehicle) {
    return (
      <div className="p-4 text-gray-600 dark:text-gray-300">
        {t("vehicles:split.no_vehicle_selected")}
      </div>
    );
  }

  return (
    <>
      {/* Product Header */}
      <div className="xl:flex justify-between items-center gap-4 pt-4 mb-4">
        <div className="flex items-center gap-4">
          <p className="text-2xl font-medium dark:text-lgb-grey-100">
            {vehicle.registrationNumber !== ""
              ? vehicle.registrationNumber
              : t("vehicles:unknown_vehicle")}
          </p>

          {!hasCopied ? (
            <Tooltip content={t("common:copy")}>
              <div
                className="bg-none rounded-md relative"
                style={{ width: "34px", height: "34px" }}
              >
                <FaRegCopy
                  className="w-6 h-6 dark:text-white cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      vehicle.name ?? t("vehicles:unknown_vehicle"),
                    );
                    setHasCopied(true);
                    setTimeout(() => setHasCopied(false), 2000); // Reset after 2 seconds
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex items-center text-lgb-grey-400 dark:text-lgb-grey-100">
              <HiCheck className="mr-2 h-5 w-5" />
              <p>{t("common:copied")}</p>
            </div>
          )}
        </div>
      </div>

      {/* <OrderKPIs orders={orders} loading={isQueryLoading} /> */}

      {/* Basic Info */}
      <SimpleList
        items={[
          {
            title: t("vehicles:details.load_capacity"),
            value: (vehicle.maxWeight || "-") + " kg",
          },
          {
            title: t("vehicles:details.vehicle_type"),
            value: vehicle.categoryCode || "-",
          },
          {
            title: t("vehicles:details.company"),
            value: company?.name,
            redirectPath: `/workspace/companies?company=${company?.name.toLowerCase().replace(/\s+/g, "-")}`,
          },
          {
            title: t("vehicles:details.description"),
            value: vehicle.name || "-",
          },
        ]}
      />

      {/* <RelatedOrders orders={orders} loading={isQueryLoading} /> */}
    </>
  );
};
