import type { FC } from "react";
import { useMemo, useState } from "react";
import { useLocations } from "../../hooks";
import { useWorkspace } from "../../context/WorkspaceContext";
import { LocationModal, PageLayout } from "../../components";
import { useTranslation } from "react-i18next";
import { EmptyState } from "../../components";
import { emptyOrdersIllustration } from "../../components/atoms/Icons/illustrations";
import { LocationsList } from "../../components/organisms/LocationsList";
import { Location } from "../../models";
import { useCache } from "../../context/CacheContext";
import { Button } from "flowbite-react";
import { HiOutlinePlus } from "react-icons/hi";
import { TableSkeleton } from "../../components";
import isSmallScreen from "../../helpers/is-small-screen";

const Locations: FC = function () {
  const [splitIsOpen, setSplitIsOpen] = useState(false);
  const { activeWorkspace } = useWorkspace();
  const [isInsertModalOpen, openInsertModal] = useState(false);
  const [locationData, setLocationData] = useState(Location.defaultLocation());
  const { updateCacheKey } = useCache();
  const { t } = useTranslation(["common", "validation", "location", "orders"]);

  // Fetch locations using useLocations
  const query = useLocations(activeWorkspace?.workspaceId ?? "");
  const locations = useMemo(() => query.data ?? [], [query]);

  // Logic to handle adding or updating locations
  const handleCreateOrUpdate = (location: Location) => {
    // Validate workspaceId
    const workspaceId = activeWorkspace?.workspaceId;
    if (!workspaceId) {
      console.error(
        "Workspace ID is undefined. Cannot create or update location.",
      );
      return;
    }

    const isNewLocation = Location.isNew(locationData);

    if (isNewLocation) {
      Location.create({
        ...location,
        workspaceId, // Now guaranteed to be a string
      })
        .then(() => {
          updateCacheKey(); // Sync UI
          query.refetch(); // Refresh location list
        })
        .catch((error) => {
          console.error("Error creating location:", error);
        });
    } else {
      Location.update(locationData, {
        ...location,
        workspaceId, // Now guaranteed to be a string
      })
        .then(() => {
          updateCacheKey(); // Sync UI
          query.refetch(); // Refresh location list
        })
        .catch((error) => {
          console.error("Error updating location:", error);
        });
    }

    openInsertModal(false); // Close modal after action
  };
  return (
    <PageLayout>
      {/* Header Section */}
      <div className="flex justify-between items-start lg:p-4">
        <div className={splitIsOpen ? "hidden lg:block" : ""}>
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            {t("location:page_title")}
          </h1>
          <p className="text-lgb-grey-600 text-base pt-2 pb-4 dark:text-lgb-grey-200">
            {t("location:page_description")}
          </p>
        </div>
        <Button
          color="light"
          onClick={() => {
            setLocationData(Location.defaultLocation());
            openInsertModal(true);
          }}
          type="submit"
          className="mt-2 sm:mt-0 mb-4 sm:mb-0"
        >
          <HiOutlinePlus className="mr-2 h-5 w-5" />
          {isSmallScreen()
            ? t("orders:create.route_selection.create_location_button_sm")
            : t("orders:create.route_selection.create_location_button")}
        </Button>
      </div>

      {/* Empty State or Locations List */}
      {query.isLoading ? (
        <TableSkeleton />
      ) : locations.length === 0 ? (
        <EmptyState
          icon={emptyOrdersIllustration}
          title={t("location:empty_state.general.title")}
          description={t("location:empty_state.general.description")}
          buttonText={t("location:empty_state.general.button_text")}
          buttonClick={() => {
            setLocationData(Location.defaultLocation());
            openInsertModal(true);
          }}
        />
      ) : (
        <LocationsList
          locations={locations}
          isLoading={query.isLoading || query.isRefetching}
          isOpen={setSplitIsOpen}
        />
      )}

      {/* Location Modal */}
      <LocationModal
        data={locationData}
        isShowing={isInsertModalOpen}
        onConfirm={(location: Location) => handleCreateOrUpdate(location)}
        onCancel={() => openInsertModal(false)}
      />
    </PageLayout>
  );
};

export default Locations;
