import { Timestamp } from "..";
import { IRouteItem } from ".";
import { IOrderProduct } from "../OrderProduct";
import { ICustomer } from "..";

export interface IOrder {
  id: string;
  customer: ICustomer;
  customerId: string;
  route: IRouteItem[];
  cargo: IOrderProduct[];
  driverId?: string;
  comment: string;
  status: OrderStatus;
  createdAt: Timestamp | null;
  createdBy: string | null;
  lastUpdated: Timestamp | null;
  workspaceId: string;


}

export enum OrderStatus {
  Draft = "Draft",
  New = "New",
  Scheduled = "Scheduled",
  InProgress = "InProgress",
  Completed = "Completed",
  Cancelled = "Cancelled",
}

export interface IContactPerson {
  name: string;
  location?: string;
  phoneNumber: string;
}

export interface IOrderValidated {
  customer?: boolean;
  route?: boolean;
  cargo?: boolean;
  driver?: boolean;
  deliveryDate?: boolean;
}
