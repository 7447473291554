import { useState, type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Location, Order } from "../../../models";
import { Tooltip } from "flowbite-react";
import { FaRegCopy } from "react-icons/fa";
import { HiCheck } from "react-icons/hi";
import { SimpleList } from "../SimpleList";
import { OrderKPIs, RelatedOrders } from "../RelatedOrders";
import { useOrders } from "../../../hooks";
import { useWorkspace } from "../../../context/WorkspaceContext";

export interface LocationDetailsProps {
  location: Location | undefined;
  sendInvoice?: () => void;
  edit: () => void;
  delete: () => void;
}

export const LocationDetails: FC<LocationDetailsProps> = (props) => {
  const { t } = useTranslation(["common", "location"]);
  const [hasCopied, setHasCopied] = useState(false);
  const { activeWorkspace } = useWorkspace();

  const query = useOrders(activeWorkspace?.workspaceId ?? "", []);
  const orders = useMemo(() => query.data ?? [], [query]) as Order[];

  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query],
  );

  return (
    <>
      {/* Location Header */}

      <div className="xl:flex justify-between items-center gap-4 pt-4 mb-4">
        <div className="flex items-center gap-4">
          <p className="text-2xl font-medium dark:text-lgb-grey-100">
            {props.location?.displayName}
          </p>

          {!hasCopied ? (
            <Tooltip content={t("common:copy")}>
              <div
                className="bg-none rounded-md relative"
                style={{ width: "34px", height: "34px" }}
              >
                <FaRegCopy
                  className="w-6 h-6 dark:text-white cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      props.location?.displayName?.toString() ?? "",
                    );
                    setHasCopied(true);
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex items-center text-lgb-grey-400 dark:text-lgb-grey-100">
              <HiCheck className="mr-2 h-5 w-5" />
              <p>{t("common:copied")}</p>
            </div>
          )}
        </div>
      </div>

      <OrderKPIs orders={orders} loading={isQueryLoading} />

      <SimpleList
        items={[
          {
            title: t("location:details.address"),
            value: props.location?.addressLine,
          },
          {
            title: t("location:details.city"),
            value: props.location?.city,
          },
          {
            title: t("location:details.postCode"),
            value: props.location?.postCode,
          },
          {
            title: t("location:details.description"),
            value: props.location?.description,
          },
          {
            title: t("location:details.countryCode"),
            value: props.location?.countryCode,
          },
        ]}
      />

      <RelatedOrders orders={orders} loading={isQueryLoading} />
    </>
  );
};
