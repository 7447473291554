import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const TextLoader: React.FC = () => {
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const { t } = useTranslation(["common"]);

  const messages: string[] = [
    t("common:loader_text.loading_initializing"),
    t("common:loader_text.loading_order"),
    t("common:loader_text.loading_vehicle"),
    t("common:loader_text.loading_credentials"),
    t("common:loader_text.loading_dashboard"),
  ];

  useEffect(() => {
    let currentIndex: number = 0;

    // Set initial loading message
    setLoadingMessage(messages[currentIndex]);
    currentIndex++;

    const interval: NodeJS.Timeout = setInterval(() => {
      setLoadingMessage(messages[currentIndex]);
      currentIndex = (currentIndex + 1) % messages.length;
    }, 2000); // Change delay as needed (milliseconds)

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="text-xl mb-4 dark:text-white">{loadingMessage}</div>
    </div>
  );
};

export default TextLoader;
