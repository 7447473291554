// src/components/molecules/CustomerDetails/CustomerDetails.tsx

import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import { FaRegCopy } from "react-icons/fa";
import { SimpleList } from "../../molecules/SimpleList";
import { Company, Driver, Vehicle } from "../../../models";
import { Timestamp } from "../../../types";

interface CompanyDetailsProps {
  company: Company | undefined;
  drivers: Driver[] | undefined;
  vehicles: Vehicle[] | undefined;
}

export const CompanyDetails: FC<CompanyDetailsProps> = ({
  drivers, //TODO: list drivers
  company,
  vehicles, //TODO: list vehicles
}) => {
  const { t } = useTranslation(["common", "vehicles", "company"]);
  const [hasCopied, setHasCopied] = useState(false);

  // If no product is selected, show a placeholder
  if (!company) {
    return (
      <div className="p-4 text-gray-600 dark:text-gray-300">
        {t("company:split.no_company_selected")}
      </div>
    );
  }

  return (
    <>
      {/* Company Header */}
      <div className="xl:flex justify-between items-center gap-4 pt-4 mb-4">
        <div className="flex items-center gap-4">
          <p className="text-2xl font-medium dark:text-lgb-grey-100">
            {company.name}
          </p>

          {!hasCopied ? (
            <Tooltip content={t("common:copy")}>
              <div
                className="bg-none rounded-md relative"
                style={{ width: "34px", height: "34px" }}
              >
                <FaRegCopy
                  className="w-6 h-6 dark:text-white cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      company.name || t("company:unknown_company"),
                    );
                    setHasCopied(true);
                    setTimeout(() => setHasCopied(false), 2000); // Reset after 2 seconds
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex items-center text-lgb-grey-400 dark:text-lgb-grey-100">
              <HiCheck className="mr-2 h-5 w-5" />
              <p>{t("common:copied")}</p>
            </div>
          )}
        </div>
      </div>

      {/* <OrderKPIs orders={orders} loading={isQueryLoading} /> */}

      {/* Basic Info */}
      <SimpleList
        items={[
          {
            title: t("company:details.contactEmail"),
            value: company.contactEmail || "-",
          },
          {
            title: t("company:details.invoiceEmail"),
            value: company.invoiceEmail || "-",
          },
          {
            title: t("company:details.organizationNumber"),
            value: company.organizationNumber || t("vehicles:unknown_vehicle"),
          },
          {
            title: t("company:details.addressLine"),
            value: company.primaryAddress?.addressLine || "-",
          },
          {
            title: t("company:details.city"),
            value: company.primaryAddress?.city || "-",
          },
          {
            title: t("company:details.postCode"),
            value: company.primaryAddress?.postCode || "-",
          },
          {
            title: t("company:details.createdAt"),
            value:
              Timestamp.toDisplayDate(company.createdAt || Timestamp.now()) ||
              "-",
          },
        ]}
      />

      {/* <RelatedOrders orders={orders} loading={isQueryLoading} /> */}
    </>
  );
};
