// src/components/molecules/CustomerDetails/CustomerDetails.tsx

import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import { FaRegCopy } from "react-icons/fa";
import { SimpleList } from "../../molecules/SimpleList";
import { Company, Driver, Order, Vehicle } from "../../../models";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { useOrders } from "../../../hooks";
import { OrderKPIs, RelatedOrders } from "../../molecules/RelatedOrders";

interface DriverDetailsProps {
  driver: Driver | undefined;
  company: Company | undefined;
  vehicle: Vehicle | undefined;
}

export const DriverDetails: FC<DriverDetailsProps> = ({
  driver,
  company,
  vehicle,
}) => {
  const { t } = useTranslation(["common", "driver", "vehicles"]);
  const [hasCopied, setHasCopied] = useState(false);
  const { activeWorkspace } = useWorkspace();

  const query = useOrders(
    activeWorkspace?.workspaceId ?? "",
    [],
    [["driverId", "==", driver?.email ?? ""]], // Extra where clause to query so we can show only related orders and KPIs
  );
  const { refetch } = query;
  const orders = useMemo(() => query.data ?? [], [query]) as Order[];

  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query],
  );

  /**
   * Refetch related orders when customer changes
   */
  useEffect(() => {
    if (driver?.email) {
      refetch(); // Trigger a refetch
    }
  }, [driver?.email, refetch]);

  // If no product is selected, show a placeholder
  if (!driver) {
    return (
      <div className="p-4 text-gray-600 dark:text-gray-300">
        {t("driver:split.no_driver_selected")}
      </div>
    );
  }

  return (
    <>
      {/* Product Header */}
      <div className="xl:flex justify-between items-center gap-4 pt-4 mb-4">
        <div className="flex items-center gap-4">
          <p className="text-2xl font-medium dark:text-lgb-grey-100">
            {driver.firstName + " " + driver.lastName}
          </p>

          {!hasCopied ? (
            <Tooltip content={t("common:copy")}>
              <div
                className="bg-none rounded-md relative"
                style={{ width: "34px", height: "34px" }}
              >
                <FaRegCopy
                  className="w-6 h-6 dark:text-white cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      driver.firstName + " " + driver.lastName ||
                        t("driver:unknown_driver"),
                    );
                    setHasCopied(true);
                    setTimeout(() => setHasCopied(false), 2000); // Reset after 2 seconds
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex items-center text-lgb-grey-400 dark:text-lgb-grey-100">
              <HiCheck className="mr-2 h-5 w-5" />
              <p>{t("common:copied")}</p>
            </div>
          )}
        </div>
      </div>

      <OrderKPIs orders={orders} loading={isQueryLoading} />

      {/* Basic Info */}
      <SimpleList
        items={[
          {
            title: t("driver:details.email"),
            value: driver.email || "-",
          },
          {
            title: t("driver:details.licenses"),
            value:
              driver.licenses.map(
                (x) =>
                  x +
                  (x === driver.licenses[driver.licenses.length - 1]
                    ? ""
                    : " - "),
              ) || "-",
          },
          {
            title: t("driver:details.vehicle"),
            value:
              vehicle?.name && vehicle?.name !== ""
                ? vehicle?.name
                : t("vehicles:unknown_vehicle"),
            redirectPath:
              vehicle?.name && vehicle?.name !== ""
                ? "/resources/vehicles"
                : undefined,
          },
          {
            title: t("driver:details.company"),
            value: company?.name,
            redirectPath: "/workspace/companies",
          },
        ]}
      />

      <RelatedOrders orders={orders} loading={isQueryLoading} />
    </>
  );
};
