"use client";

import { Dropdown } from "flowbite-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HiChevronDown } from "react-icons/hi";
import { InputValidationResult } from "../../../types";

type DropdownType = "checkbox" | "radio" | "normal";

interface DropdownItem {
  key: string;
  value: string;
  label?: string;
  description?: string;
}

interface CustomSelectProps {
  type: DropdownType;
  items: DropdownItem[];
  label?: string;
  defaultValue?: string | string[];
  validation?: InputValidationResult;
  disabled?: boolean; // NEW: Add disabled prop
  onSelectChanged?: (selected: string[] | string | null) => void;
}

export function CustomSelect({
  type = "normal",
  items,
  label = "Dropdown label",
  defaultValue,
  validation,
  disabled = false, // NEW: Default value for disabled
  onSelectChanged,
}: CustomSelectProps) {
  const [selectedItems, setSelectedItems] = useState<string[] | string | null>(
    defaultValue || (type === "checkbox" ? [] : type === "radio" ? null : null),
  );
  const { t } = useTranslation(["common"]);

  const handleCheckboxChange = (value: string) => {
    if (disabled) return; // Prevent interaction if disabled

    setSelectedItems((prev) =>
      Array.isArray(prev)
        ? prev.includes(value)
          ? prev.filter((item) => item !== value)
          : [...prev, value]
        : [value],
    );

    if (onSelectChanged) {
      const updatedSelection =
        Array.isArray(selectedItems) && selectedItems.includes(value)
          ? selectedItems.filter((item) => item !== value)
          : [...(selectedItems as string[]), value];
      onSelectChanged(updatedSelection);
    }
  };

  const handleRadioChange = (value: string) => {
    if (disabled) return; // Prevent interaction if disabled

    setSelectedItems(value);
    if (onSelectChanged) onSelectChanged(value);
  };

  const getLabel = () => {
    // Radio
    if (typeof selectedItems === "string") {
      let option = items.filter((x) => x.key === selectedItems)[0];
      return option ? option.label : " ";
    }
    // Nothing selected
    if (!selectedItems || (selectedItems && selectedItems.length === 0)) {
      return label;
      // One item selected
    } else if (selectedItems && selectedItems.length === 1) {
      let option = items.filter((x) => x.key === selectedItems[0])[0];
      return option ? option.label : " ";
      // Multiple selected
    } else {
      return selectedItems.length + " " + t("common:selected");
    }
  };

  const getValidationMessage = () => {
    if (validation?.isMissing) {
      return validation?.fieldName + " " + t("common:errors.required");
    }
    if (validation?.isInvalid) {
      if (validation?.errorMessage !== undefined) {
        return validation?.errorMessage;
      }
      return validation?.fieldName + " " + t("common:errors.invalid");
    }
    return "";
  };

  const renderItems = () => {
    return items.map((option) => (
      <div
        key={option.value}
        tabIndex={0}
        className={`flex gap-2 px-2 py-2 focus:border-none hover:bg-gray-100 dark:hover:bg-slate-600 rounded-tl-lg ${
          disabled ? "opacity-50 cursor-not-allowed" : "hover:cursor-pointer"
        }`}
      >
        {type === "checkbox" && (
          <input
            type="checkbox"
            className="mt-1 text-lgb-primary dark:text-lgb-primary-lighten rounded focus:border focus:ring-0 focus:ring-white dark:focus:ring-slate-600"
            id={option.label}
            disabled={disabled}
            checked={
              Array.isArray(selectedItems) &&
              selectedItems.includes(option.value)
            }
            onChange={() => handleCheckboxChange(option.value)}
          />
        )}
        {type === "radio" && (
          <input
            type="radio"
            className="mt-1 text-lgb-primary dark:text-lgb-primary-lighten"
            id={option.label}
            name="dropdown-radio"
            disabled={disabled}
            checked={selectedItems === option.value}
            onChange={() => handleRadioChange(option.value)}
          />
        )}
        <label
          htmlFor={option.label}
          className={`w-full ${
            disabled ? "cursor-not-allowed" : "hover:cursor-pointer"
          }`}
        >
          <span className={option.description ? "font-medium" : ""}>
            {option.label}
          </span>
          {option.description && option.description !== "" && (
            <div className="opacity-60 max-w-96">{option.description}</div>
          )}
        </label>
      </div>
    ));
  };

  return (
    <div>
      <Dropdown
        inline
        theme={{
          inlineWrapper: "flex items-center w-full",
        }}
        disabled={disabled}
        arrowIcon={false}
        label={
          <div
            className={`w-full flex items-center justify-between bg-white rounded-lg pl-4 p-2 border p-2.5 text-sm ${
              disabled
                ? "cursor-not-allowed opacity-50 border-gray-300 pointer-events-none"
                : "cursor-pointer border-gray-500 dark:bg-lgb-dark-background dark:text-white dark:border-gray-700"
            }`}
          >
            <span
              className={`text-gray-600 ${
                disabled ? "opacity-50" : "dark:text-gray-400"
              }`}
            >
              {getLabel()}
            </span>
            <HiChevronDown
              className={`text-gray-600 ${
                disabled ? "opacity-50" : "dark:text-gray-400"
              }`}
            />
          </div>
        }
        dismissOnClick={type === "normal"}
      >
        {!disabled && (
          <div className="max-h-80 overflow-y-scroll">{renderItems()}</div>
        )}
      </Dropdown>
      {validation?.show && (
        <p className="mt-2 text-sm text-red-500">{getValidationMessage()}</p>
      )}
    </div>
  );
}
