import { useEffect, useState, useMemo, useRef, FC } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  OverlayView,
  Polyline,
} from "@react-google-maps/api";
import axios from "axios";
import MapPin from "../../assets/icons/MapPin.svg";
import MapPinStart from "../../assets/icons/MapPinStart.svg";
import MapPinDriver from "../../assets/icons/MapPinDriver.svg";
import { useDarkMode } from "../../context/DarkModeContext";
import {
  LGBDatepicker,
  PageLayout,
  SplitTable,
  StatusBadge,
} from "../../components";
import { ICustomer, IRouteItem, ITableRow, Timestamp } from "../../types";
import { useTranslation } from "react-i18next";
import { OrderStatus } from "../../types/order/IOrder";
import InfoCard from "../../components/molecules/Cards/InfoCard";
import { HiOutlineTruck, HiOutlineUser, HiShoppingBag } from "react-icons/hi";
import { useDrivers, useOrders, useVehicles } from "../../hooks";
import { useWorkspace } from "../../context/WorkspaceContext";
import { Order } from "../../models";
import Driver from "./../../models/Driver";
import MapStopCard from "../../components/molecules/Cards/MapStopCard";
import { useSearchParams } from "react-router-dom";
import { getDriverPositionByEmail } from "../../api";
import { useUserSession } from "../../context/UserContext";
import { upcomingMapPage } from "../../components/atoms/Icons/illustrations";

import moment from "moment";
import { CustomSelect } from "../../components/atoms/Select/CustomSelect";

const libraries: ("marker" | "places" | "geometry")[] = [
  "marker",
  "places",
  "geometry",
];
const customStyles: google.maps.MapTypeStyle[] = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  // ... (Include all your custom styles here)
];

export interface IMapRoute {
  customerName: string;
  customer: ICustomer;
  id: string;
  type: string;
  status: OrderStatus;
  driver?: Driver;
  route: IRouteItem[];
}

const containerStyle = {
  width: "100%",
  height: "680px",
};

const initialCenter = {
  lat: 62.75769482961424,
  lng: 16.89871699772572,
};

export const DriverMapPage: FC = function () {
  const { t } = useTranslation(["common", "orders", "validation", "stats"]);
  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries: ["marker", "places", "geometry"],
  });
  const { isDarkMode } = useDarkMode();
  const { activeWorkspace } = useWorkspace();
  const { userSession } = useUserSession(); // 1. Access user info

  const [originalOrders, setOriginalOrders] = useState<IMapRoute[]>([]);
  const [filteredOrders, setfilteredOrders] = useState<IMapRoute[]>([]);

  const [selectedRoute, setSelectedRoute] = useState<IMapRoute>();
  const [selectedStop, setSelectedStop] = useState<IRouteItem>();
  const [tableRows, setTableRows] = useState<ITableRow[]>([]);
  const [paths, setPaths] = useState<{
    [key: string]: google.maps.LatLngLiteral[];
  }>({});
  const [pathKey, setPathKey] = useState<number>(0);
  const [selectedDrivers, setSelectedDrivers] = useState<string[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [searchText, setSearchText] = useState("");

  const [driverPosition, setDriverPosition] =
    useState<google.maps.LatLngLiteral>();
  const [searchParams, setSearchParams] = useSearchParams();
  /***********
   * QUERIES *
   ***********/
  const query = useOrders(activeWorkspace?.workspaceId ?? "", [
    OrderStatus.Scheduled,
    OrderStatus.New,
    OrderStatus.InProgress,
    OrderStatus.Completed,
  ]);
  const driverQuery = useDrivers(activeWorkspace?.workspaceId ?? "");
  const vehiclesQuery = useVehicles(activeWorkspace?.workspaceId ?? "");
  const orders = useMemo(() => query.data ?? [], [query]) as Order[];
  const drivers = useMemo(() => driverQuery.data ?? [], [driverQuery]);
  const vehicles = useMemo(() => vehiclesQuery.data ?? [], [vehiclesQuery]);
  const mapRef = useRef<google.maps.Map | null>(null);
  const polylineRef = useRef<google.maps.Polyline | null>(null); // Reference to the Polyline

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  /**
   * HANDLE FILTER CHANGES
   */
  useEffect(() => {
    let filtered = [...originalOrders];

    // Filter by date
    if (selectedDate) {
      filtered = filtered.filter((order) => {
        const orderDate = Timestamp.toMoment(order.route[0].stopDate).startOf(
          "day",
        );
        const selectedDateMoment = moment(selectedDate).startOf("day");
        return orderDate.isSame(selectedDateMoment);
      });
    }

    // Filter by selected drivers
    if (selectedDrivers.length > 0) {
      filtered = filtered.filter((order) =>
        selectedDrivers.includes(order.driver?.email || ""),
      );
    }

    // Filter by selected types ✔
    if (selectedTypes.length > 0) {
      filtered = filtered.filter((order) =>
        selectedTypes.includes(order.status),
      );
    }

    // Filter by search text
    if (searchText) {
      const lowerCaseSearchText = searchText.toLowerCase();
      filtered = filtered.filter((order) =>
        order.customerName.toLowerCase().includes(lowerCaseSearchText),
      );
    }

    setfilteredOrders(filtered);
  }, [
    selectedDate,
    selectedDrivers,
    selectedTypes,
    searchText,
    originalOrders,
  ]);

  /**************
   * TABLE ROWS *
   **************/
  useEffect(() => {
    if (filteredOrders) {
      setTableRows(
        filteredOrders.map((order) => ({
          id: order.id,
          isActive: selectedRoute ? selectedRoute.id === order.id : false,
          onRowClickData: order,
          cells: [
            {
              id: "customer",
              children: order.customer.isCompany
                ? order.customer.name || ""
                : `${order.customer.firstName || ""} ${order.customer.lastName || ""}`.trim(),
            },
            {
              id: "type",
              children: "Pickup", //TODO
            },
            {
              id: "status",
              children: <StatusBadge status={order.status} />,
              showOnSmallScreen: true,
            },
          ],
        })),
      );
    }
  }, [filteredOrders, selectedRoute]);

  /**
   * Maps the orders to a suitable object to work with in the map.
   */
  useEffect(() => {
    const mappedOrders = orders
      .filter((x) => x.status !== OrderStatus.Cancelled) // Exclude cancelled orders
      .map((x) => ({
        customerName: x.customer.isCompany
          ? x.customer.name || ""
          : `${x.customer.firstName || ""} ${x.customer.lastName || ""}`.trim(),
        customer: x.customer,
        id: x.id,
        type: "Pickup",
        status: x.status,
        driver: drivers.find((d) => d.email === x.driverId),
        route: x.route,
      }));
    setOriginalOrders(mappedOrders || []);
    setfilteredOrders(mappedOrders || []);
  }, [orders, drivers]);

  /**
   * Handle URL query parameters to select a specific order on load.
   */
  useEffect(() => {
    const orderId = searchParams.get("orderId");
    if (
      orderId &&
      filteredOrders.length > 0 &&
      (!selectedRoute || selectedRoute.id !== orderId)
    ) {
      const matchingOrder = filteredOrders.find(
        (order) => order.id === orderId,
      );
      if (matchingOrder) {
        setRoute(matchingOrder);
      }
    }
  }, [searchParams, filteredOrders]);

  /**
   * Fetches the route path from the Google Routes API.
   *
   * @param coordinates An array of lat/long coordinates
   */
  const fetchRoutePath = async (
    coordinates: google.maps.LatLngLiteral[],
    id: string,
  ) => {
    if (coordinates.length < 2) {
      // Not enough coordinates to draw a path
      setPathKey((prevKey) => prevKey + 1); // Increment pathKey
      return;
    }
    try {
      const response = await axios.post(
        `https://routes.googleapis.com/directions/v2:computeRoutes`,
        {
          origin: {
            location: {
              latLng: {
                latitude: coordinates[0].lat,
                longitude: coordinates[0].lng,
              },
            },
          },
          destination: {
            location: {
              latLng: {
                latitude: coordinates[coordinates.length - 1].lat,
                longitude: coordinates[coordinates.length - 1].lng,
              },
            },
          },
          intermediates: coordinates.slice(1, -1).map((stop) => ({
            location: {
              latLng: {
                latitude: stop.lat,
                longitude: stop.lng,
              },
            },
          })),
          travelMode: "DRIVE",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Goog-Api-Key": process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
            "X-Goog-FieldMask": "routes.polyline",
          },
        },
      );

      if (
        response.data &&
        response.data.routes &&
        response.data.routes.length > 0
      ) {
        const route = response.data.routes[0];
        const path = window.google.maps.geometry.encoding
          .decodePath(route.polyline.encodedPolyline)
          .map((stop) => ({
            lat: stop.lat(),
            lng: stop.lng(),
          }));

        setPaths((prev) => ({
          ...prev,
          [id]: path,
        }));
      }
    } catch (error) {
      console.error("Error fetching route from Routes API:", error);
    }
  };

  /**
   * Attempts to fetch driver position every 5 seconds.
   */
  useEffect(() => {
    async function getPosition() {
      let position = await getDriverPositionByEmail(
        userSession?.token.accessToken ?? "",
        activeWorkspace?.workspaceId ?? "",
        selectedRoute?.driver?.email ?? "",
      );
      if (position && position?.lastLocation) {
        setDriverPosition({
          lat: position.lastLocation.lat,
          lng: position.lastLocation.lon,
        });
      } else {
        setDriverPosition(undefined);
      }
    }

    if (selectedRoute && activeWorkspace && userSession) {
      getPosition(); // <-- Fetch position immediately on change
      const intervalId = setInterval(async () => {
        await getPosition();
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [selectedRoute, activeWorkspace, userSession]);

  /**
   * Sets the selected route and updates the URL query parameter.
   *
   * @param mapRoute Selected route.
   */
  const setRoute = (mapRoute: IMapRoute) => {
    if (selectedRoute && selectedRoute.id === mapRoute.id) return;
    setDriverPosition(undefined);
    // Remove existing polyline from the map
    if (polylineRef.current) {
      polylineRef.current.setMap(null);
      polylineRef.current = null;
    }

    setSelectedRoute(mapRoute);
    setSearchParams({ orderId: mapRoute.id });

    if (mapRef.current) {
      const coordinates = mapRoute.route
        .filter((x) => x.location.coordinate?.lat && x.location.coordinate?.lon)
        .map((x) => ({
          lat: x.location.coordinate?.lat ?? 0,
          lng: x.location.coordinate?.lon ?? 0,
        }));

      setBoundsAndCenterpoint(coordinates);

      // Fetch the path for the selected route
      if (!paths[mapRoute.id]) {
        fetchRoutePath(coordinates, mapRoute.id);
      }

      setPathKey((prevKey) => prevKey + 1);
    }
  };

  /**
   * Calculates and sets the centerpoint and bounds of the map to fit the route.
   *
   * @param coordinates An array of lat/lng coordinates.
   */
  const setBoundsAndCenterpoint = (
    coordinates: google.maps.LatLngLiteral[],
  ) => {
    if (mapRef.current && coordinates.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      coordinates.forEach((coord) => bounds.extend(coord));
      mapRef.current.fitBounds(bounds);
    }
  };

  const selectStop = (stop?: IRouteItem) => {
    setSelectedStop(stop);
    // No map movement when selecting a stop
  };

  /*************
   * PIN ICONS *
   *************/
  const waypointPin = useMemo<google.maps.Icon | undefined>(() => {
    if (!isLoaded) return undefined;
    return {
      url: MapPin,
      scaledSize: new window.google.maps.Size(28, 28),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(14, 14),
      labelOrigin: new window.google.maps.Point(14, 14),
    };
  }, [isLoaded]);

  const startPin = useMemo<google.maps.Icon | undefined>(() => {
    if (!isLoaded) return undefined;
    return {
      url: MapPinStart,
      scaledSize: new window.google.maps.Size(28, 28),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(14, 14),
      labelOrigin: new window.google.maps.Point(14, 14),
    };
  }, [isLoaded]);

  const driverPin = useMemo<google.maps.Icon | undefined>(() => {
    if (!isLoaded) return undefined;
    return {
      url: MapPinDriver,
      scaledSize: new window.google.maps.Size(28, 28),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(14, 14),
      labelOrigin: new window.google.maps.Point(14, 14),
    };
  }, [isLoaded]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  if (!userSession?.user?.isAdmin) {
    return (
      <PageLayout>
        <div className="flex flex-col items-center justify-center py-8">
          {upcomingMapPage}
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4 mt-8">
            {t("common:map_coming_soon")}
          </h1>
          <p className="text-base text-gray-600 dark:text-lgb-grey-200">
            {t("common:feature_in_development")}
          </p>
        </div>
      </PageLayout>
    );
  }
  return (
    <PageLayout>
      <SplitTable
        tableWidth="w-1/3"
        contentWidth="w-2/3"
        searchable
        forceOpen
        tableRows={tableRows}
        onRowClick={(order) => setRoute(order)}
        updateTable={setSearchText}
        contentOverTable={
          <div className="grid grid-cols-2 gap-2 justify-between pb-4">
            <div className="col-span-1 w-full">
              <CustomSelect
                type="checkbox"
                label={t("orders:create.driver_selection.title")}
                items={drivers.map((x) => ({
                  key: x?.email ?? "",
                  value: x?.email ?? "",
                  label: x?.firstName + " " + x?.lastName,
                }))}
                onSelectChanged={(selected: any) =>
                  setSelectedDrivers(selected)
                }
              />
            </div>
            <div className="col-span-1">
              <CustomSelect
                type="checkbox"
                label={t("orders:choose_status")}
                items={[
                  {
                    key: OrderStatus.InProgress,
                    value: OrderStatus.InProgress,
                    label: t("stats:orderStatus.in_progress"),
                  },
                  {
                    key: OrderStatus.New,
                    value: OrderStatus.New,
                    label: t("stats:orderStatus.scheduled"),
                  },
                  {
                    key: OrderStatus.Completed,
                    value: OrderStatus.Completed,
                    label: t("stats:orderStatus.completed"),
                  },
                ]}
                onSelectChanged={(e: any) => {
                  setSelectedTypes(e);
                }}
              />
            </div>
            <div className="col-span-2">
              <LGBDatepicker
                select={setSelectedDate}
                selectedDate={selectedDate}
              />
            </div>
          </div>
        }
        content={
          <div className="w-full">
            <div className="flex justify-between gap-4 mb-4">
              <div className="w-full">
                <InfoCard
                  icon={<HiOutlineUser className="h-7 w-7 text-lgb-grey-600" />}
                  title="Driver"
                  description={
                    selectedRoute && selectedRoute.driver
                      ? selectedRoute.driver.firstName +
                        " " +
                        selectedRoute.driver.lastName
                      : "--"
                  }
                  titleClass="text-lgb-grey-600"
                  descriptionClass="text-lgb-grey-600"
                  containerClass="bg-lgb-grey-100"
                />
              </div>
              <div className="w-full">
                <InfoCard
                  icon={
                    <HiOutlineTruck className="h-7 w-7 text-lgb-grey-600" />
                  }
                  title="Vehicle"
                  description={
                    vehicles.find(
                      (x) => x.id === selectedRoute?.driver?.vehicleId || "",
                    )?.registrationNumber || "--"
                  }
                  titleClass="text-lgb-grey-600"
                  descriptionClass="text-lgb-grey-600"
                  containerClass="bg-lgb-grey-100"
                />
              </div>
              <div className="w-full">
                <InfoCard
                  icon={<HiShoppingBag className="h-7 w-7 text-lgb-grey-600" />}
                  title="Customer"
                  description={selectedRoute?.customerName || "--"}
                  titleClass="text-lgb-grey-600"
                  descriptionClass="text-lgb-grey-600"
                  containerClass="bg-lgb-grey-100"
                />
              </div>
            </div>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={initialCenter}
              zoom={4}
              onLoad={onLoad}
              options={{
                streetViewControl: true,
                mapTypeControl: true,
                styles: isDarkMode ? customStyles : null,
                gestureHandling: "greedy",
              }}
            >
              {selectedRoute &&
                selectedRoute.route.map((stop, index) => (
                  <Marker
                    key={index}
                    position={{
                      lat: stop.location.coordinate?.lat ?? 0,
                      lng: stop.location.coordinate?.lon ?? 0,
                    }}
                    icon={index === 0 ? startPin : waypointPin}
                    onClick={() => selectStop(stop)}
                  />
                ))}

              {/* Always render Polyline to ensure onLoad is called */}
              {selectedRoute && (
                <>
                  {driverPosition && (
                    <Marker position={driverPosition} icon={driverPin} />
                  )}
                  <Polyline
                    key={`polyline-${pathKey}`}
                    path={paths[selectedRoute.id]}
                    options={{
                      strokeColor: "#7214FF",
                      strokeOpacity: 0.8,
                      strokeWeight: 4,
                    }}
                    onLoad={(polyline) => {
                      // Remove existing polyline from the map if any
                      if (polylineRef.current) {
                        polylineRef.current.setMap(null);
                      }
                      polylineRef.current = polyline;
                    }}
                  />
                </>
              )}
              {selectedRoute && selectedStop && (
                <OverlayView
                  position={{
                    lat: selectedStop?.location.coordinate?.lat ?? 0,
                    lng: selectedStop?.location.coordinate?.lon ?? 0,
                  }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <MapStopCard
                    routeInfo={selectedRoute}
                    stopInfo={selectedStop}
                    close={() => selectStop(undefined)}
                  />
                </OverlayView>
              )}
            </GoogleMap>
          </div>
        }
        tableHeaders={[
          {
            id: "customer",
            children: t("common:customer"),
          },
          {
            id: "type",
            children: t("common:date"),
          },
          {
            id: "status",
            children: t("common:status"),
          },
        ]}
      />
    </PageLayout>
  );
};
