import { type FC } from "react";
import { Label, Button } from "flowbite-react";
import { Dialog } from "../..//molecules/Dialog";

export interface FormFieldProp {
  id: string;
  label: string;
  input: React.ReactNode;
  hidden?: boolean | false;
  grid_style?: string | undefined;
}

export interface DialogFormProp {
  show: boolean;
  buttonConfirmColor?: string;
  buttonConfirmText?: string;
  buttonConfirmPrependIcon?: React.ReactNode;
  buttonCloseText?: string;
  buttonClosePrependIcon?: React.ReactNode;
  title: string;
  toggleModal: () => void;
  confirmButton: (data: any) => void;
  showHeader?: boolean | false;
  header?: React.ReactNode;
  dismissible?: boolean;
  toggle?: FormFieldProp;
  fields?: Array<FormFieldProp>;
  grid_style?: string;
}

export const DialogForm: FC<DialogFormProp> = (props) => {
  function handleNestedField(data: any, fieldId: any, fieldData: any) {
    const nestedFields = fieldId.split(".");
    // If one level, we save the data as is
    if (nestedFields.length === 1) {
      return setDataField(data, fieldId, fieldData);
    }
    // Extract child data element from the data field if it exists
    var childData = {};
    if (data.hasOwnProperty(nestedFields[0])) {
      childData = data[nestedFields[0]];
    }
    // Enrich child data
    childData = setDataField(
      childData,
      nestedFields.slice(1).join("."),
      fieldData,
    );

    return { ...data, [nestedFields[0]]: childData };
  }

  function setDataField(data: any, fieldId: any, fieldData: any) {
    return { ...data, [fieldId]: fieldData };
  }

  function handleSubmit(event: any) {
    // Prevent default behavior
    event.preventDefault();

    const formData = new FormData(event.target);
    var data = {};
    if (props.fields) {
      props.fields.forEach((field) => {
        const fieldId = field.id;
        const fieldData = formData.get(field.id);

        data = handleNestedField(data, fieldId, fieldData);
      });
    }

    props.confirmButton(data);
  }

  const dialogContent = (
    <div>
      {props.showHeader ? <div className="mb-4">{props.header}</div> : null}
      <form onSubmit={handleSubmit}>
        <div
          className={
            props.grid_style ? props.grid_style : "grid grid-cols-2 gap-4"
          }
        >
          {props.toggle ? (
            <div className="col-span-full">
              <div key={props.toggle.id}>{props.toggle.input}</div>
            </div>
          ) : null}
          {props.fields &&
            props.fields.map((field) => {
              if (field.hidden) return null;
              return (
                <div key={field.id} className={field.grid_style ?? ""}>
                  <div className="mb-2 block">
                    <Label htmlFor={field.id} value={field.label} />
                  </div>
                  {field.input}
                </div>
              );
            })}
        </div>
        <div className="pt-6 flex gap-4">
          <Button
            color={
              props.buttonConfirmColor ? props.buttonConfirmColor : "primary"
            }
            type="submit"
          >
            {props.buttonConfirmPrependIcon}
            {props.buttonConfirmText}
          </Button>
          <Button color="gray" onClick={() => props.toggleModal()}>
            {props.buttonClosePrependIcon}
            {props.buttonCloseText}
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <Dialog
      title={props.title}
      content={dialogContent}
      dismissible={props.dismissible}
      show={props.show}
      toggleModal={props.toggleModal}
      hideButtons={true}
    />
  );
};
