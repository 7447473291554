import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  HiOutlineClipboardList,
  HiOutlineClock,
  HiOutlineBadgeCheck,
} from "react-icons/hi";
import { HiOutlineMapPin } from "react-icons/hi2";
import InfoCard from "../../molecules/Cards/InfoCard";
import { OrderStatus } from "../../../types/order/IOrder";
import { IRouteItem } from "../../../types";
import { Order } from "../../../models";

interface OrderKPIProps {
  orders: Order[];
  loading: boolean;
}

export const OrderKPIs: FC<OrderKPIProps> = ({ orders, loading }) => {
  const { t } = useTranslation(["common", "customer"]);
  // KPIs
  const [totalOrders, setTotalOrders] = useState(0);
  const [upcomingOrdersCount, setUpcomingOrdersCount] = useState(0);
  const [uniqueLocationsCount, setUniqueLocationsCount] = useState(0);

  const [allCompletedOrdersCount, setAllCompletedOrdersCount] = useState(0);

  // Fetch KPIs and initial orders
  useEffect(() => {
    if (!orders) return;

    const calculateKPIs = async () => {
      try {
        const completed = orders.filter(
          (order) => order.status === OrderStatus.Completed,
        );

        const upcoming = orders.filter(
          (order) =>
            order.status === OrderStatus.New ||
            order.status === OrderStatus.Scheduled ||
            order.status === OrderStatus.InProgress,
        );

        // Calculate unique locations
        const uniqueLocs = new Set<string>();
        orders.forEach((order) => {
          order.route.forEach((routeItem: IRouteItem) => {
            if (routeItem.location && routeItem.location.id) {
              // Ensure 'location.id' exists
              uniqueLocs.add(routeItem.location.id);
            }
          });
        });

        setUpcomingOrdersCount(upcoming.length);
        setUniqueLocationsCount(uniqueLocs.size);
        setAllCompletedOrdersCount(completed.length);
        setTotalOrders(orders.length);
      } catch (error) {
        console.error("Error fetching customer data:", error);
        // Optionally, set error state to display a message to the user
      }
    };

    calculateKPIs();
  }, [orders, t]);

  return (
    <>
      {/* KPIs */}
      <div className="flex flex-col sm:flex-row items-start gap-4 my-6">
        <InfoCard
          icon={<HiOutlineBadgeCheck className="h-7 w-7 text-lgb-grey-600" />}
          title={t("customer:completed_orders")}
          description={`${allCompletedOrdersCount}`}
          titleClass="text-lgb-grey-600"
          descriptionClass="text-lgb-grey-600 text-center"
          containerClass="bg-lgb-green-100"
          loading={loading}
        />
        <InfoCard
          icon={<HiOutlineClock className="h-7 w-7 text-lgb-grey-600" />}
          title={t("customer:upcoming_orders")}
          description={`${upcomingOrdersCount}`}
          titleClass="text-lgb-grey-600"
          descriptionClass="text-lgb-grey-600 text-center"
          containerClass="bg-lgb-yellow-100"
          loading={loading}
        />
        <InfoCard
          icon={<HiOutlineMapPin className="h-7 w-7 text-lgb-grey-600" />}
          title={t("customer:unique_locations")}
          description={`${uniqueLocationsCount}`}
          titleClass="text-lgb-grey-600"
          descriptionClass="text-lgb-grey-600 text-center"
          containerClass="bg-lgb-blue-100"
          loading={loading}
        />
        <InfoCard
          icon={
            <HiOutlineClipboardList className="h-7 w-7 text-lgb-grey-600" />
          }
          title={t("customer:total_orders")}
          description={`${totalOrders}`}
          titleClass="text-lgb-grey-600"
          descriptionClass="text-lgb-grey-600 text-center"
          containerClass="bg-lgb-grey-100"
          loading={loading}
        />
      </div>
    </>
  );
};
