/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, type FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  CustomerModal,
  EmptyState,
  DropdownSearch,
  DropdownSearchRadioItem,
  DialogNotify,
} from "../../../../components";
import { Customer } from "../../../../models";
import { useWorkspace } from "../../../../context/WorkspaceContext";
import { useCache } from "../../../../context/CacheContext";
import { useCustomers } from "../../../../hooks";
import customersEmptyState from "../../../../assets/customersEmptyState.svg";
import { TableSkeletonsByAmount } from "../../../../components/atoms/Skeleton/TableSkeleton";
import { ICustomer } from "../../../../types";
import { HiCheck } from "react-icons/hi";

export interface CustomerSelectionProps {
  selectedCustomer: Customer;
  validationFailed: boolean;
  update: (customer: Customer) => void;
}

const CustomerSelection: FC<CustomerSelectionProps> = (props) => {
  const { t } = useTranslation(["orders", "validation"]);
  const [showDialog, setShowDialog] = useState(false);
  const [current, setCurrent] = useState(props.selectedCustomer);
  const { activeWorkspace } = useWorkspace();
  const { updateCacheKey } = useCache();
  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState([] as Customer[]);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const query = useCustomers(activeWorkspace?.workspaceId ?? "");
  const customers = query.data;

  useEffect(() => {
    if (searchText.length) {
      let companies =
        customers?.filter(
          (x) =>
            x.isCompany &&
            x.name?.toLowerCase().includes(searchText.toLowerCase()),
        ) || [];
      let privatePersons =
        customers?.filter(
          (x) =>
            !x.isCompany &&
            (x.firstName + " " + x.lastName)
              ?.toLowerCase()
              .includes(searchText.toLowerCase()),
        ) || [];
      setSearchResults(companies.concat(privatePersons));
    } else {
      setSearchResults(customers || []);
    }
  }, [searchText, customers]);

  useEffect(() => {
    setCurrent(props.selectedCustomer);
  }, [props.selectedCustomer]);

  const CreateCustomerDialog = (
    <div>
      <CustomerModal
        data={Customer.default()}
        isShowing={showDialog}
        onConfirm={async (formData: ICustomer) => {
          const result = await Customer.create(
            activeWorkspace?.workspaceId ?? "",
            formData,
          );
          toggleDialog();
          updateCacheKey();
          if (result && result.id) {
            setShowSuccessDialog(!showSuccessDialog);
            selectCustomer(result);
          }
        }}
        onCancel={() => toggleDialog()}
      />

      <DialogNotify
        show={showSuccessDialog}
        isLoading={false}
        icon={
          <div
            className="bg-green-100 rounded-4xl"
            style={{ width: "48px", height: "48px" }}
          >
            <HiCheck size={"48px"} className="text-green-500" />
          </div>
        }
        buttonConfirmColor="primary700"
        buttonConfirmText={t("common:proceed")}
        title={t(
          "orders:create.customer_selection.create_customer_dialog.add_success_text",
        )}
        toggleModal={() => setShowSuccessDialog(!showSuccessDialog)}
        confirmButton={() => setShowSuccessDialog(!showSuccessDialog)}
      />
    </div>
  );

  if (!customers?.length && !query.isLoading) {
    return (
      <div>
        <EmptyState
          icon={
            <img
              width={160}
              height={160}
              src={customersEmptyState}
              alt="No customers created"
            />
          }
          title={t("create.customer_selection.empty_title")}
          description={t("create.customer_selection.empty_description")}
          buttonText={t("create.customer_selection.create_customer_button")}
          buttonClick={() => toggleDialog()}
        />

        {CreateCustomerDialog}
      </div>
    );
  }

  function toggleDialog() {
    setShowDialog(!showDialog);
  }
  /**
   * Handle search logic
   * @param val search string
   */
  function handleSearch(val: string) {
    setSearchText(val);
  }

  const selectCustomer = (val: Customer) => {
    setCurrent(val);
    props.update(val);
  };
  return (
    <div>
      <DropdownSearch
        placeholder={t("create.customer_selection.search_ph")}
        inputChanged={(val) => handleSearch(val)}
        buttonText={t("create.customer_selection.create_customer_button")}
        buttonClick={toggleDialog}
        showResults
        plain
      >
        <div>
          {query.isLoading && <TableSkeletonsByAmount quantity={6} />}
          {!query.isLoading &&
            searchResults.map((customer: Customer) => {
              return (
                <DropdownSearchRadioItem
                  key={customer.id}
                  selected={current.id === customer.id}
                  buttonClickEvent={() => selectCustomer(customer)}
                  id={customer.id}
                  title={
                    customer.isCompany
                      ? (customer.name ?? "")
                      : customer.firstName + " " + customer.lastName
                  }
                ></DropdownSearchRadioItem>
              );
            })}
        </div>
      </DropdownSearch>
      {CreateCustomerDialog}
    </div>
  );
};

export default CustomerSelection;
