/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useEffect, useReducer, useCallback } from "react";
import { ToggleSwitch } from "flowbite-react";
import { HiOutlinePlus } from "react-icons/hi";
import {
  StandardForm,
  Dialog,
  SelectCountry,
  StyledTextInput,
  DropdownSearch,
  DropdownSearchListItem,
} from "../..";
import { ICustomer, InputValidationResult } from "../../../types";
import { useTranslation } from "react-i18next";
import { Customer } from "../../../models";
import {
  validateHasValue,
  validateEmail,
  validateOrganizationNumber,
  validatePostCode,
  validateStringLength,
  validateMobile,
  hasValidationError,
} from "../../../helpers/validationHelper";
import { TableSkeletonsByAmount } from "../../atoms/Skeleton/TableSkeleton";
import _debounce from "lodash/debounce";
import { handleNestedField } from "../../../helpers/handleNestedFields";

export interface CustomerModalProp {
  data: ICustomer;
  isShowing: boolean;
  onConfirm: (data: ICustomer) => void;
  onCancel: () => void;
}

interface ProductAction {
  type: string;
  payload: { key: string; value: any };
}

const customerReducer = (
  state: ICustomer,
  action: ProductAction,
): ICustomer => {
  switch (action.type) {
    case "UPDATE_STATE":
      return {
        ...action.payload.value,
      };
    case "UPDATE_FIELD":
      return {
        ...handleNestedField(state, action.payload.key, action.payload.value),
      };
    default:
      return state;
  }
};

export const CustomerForm: FC<CustomerModalProp> = function (
  props: CustomerModalProp,
) {
  const isNew = Customer.isNew(props.data);
  const [isCompany, setIsCompany] = useState<boolean>(
    isNew ? true : (props.data?.isCompany ?? false),
  );

  const { t } = useTranslation(["common", "customer", "modals"]);
  const [isValidated, setIsValidated] = useState(false);
  const [validations, setValidations] = useState([] as InputValidationResult[]);
  const [customer, dispatch] = useReducer(customerReducer, props.data);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<any>();
  const [showNameResults, setShowNameResults] = useState<boolean>(false);
  const [showOrgNumberResults, setShowOrgNumberResults] =
    useState<boolean>(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(_debounce(fetchCompanyData, 1000), []);

  async function fetchCompanyData(
    name?: string,
    orgnr?: string,
  ): Promise<void> {
    const url = `https://data.brreg.no/enhetsregisteret/api/enheter?size=20${name ? `&navn=${name}` : ""}${orgnr ? `&organisasjonsnummer=${orgnr}` : ""}`;
    if (name?.length || orgnr?.length) {
      try {
        const response = await fetch(url, {
          method: "GET",
        });

        switch (response.status) {
          case 200:
            let data = await response.json();
            if (data._embedded?.enheter && data._embedded.enheter?.length) {
              setSearchResults(
                data._embedded.enheter.map((x: any) => {
                  let newCustomer = Customer.default();

                  newCustomer.setData({
                    name: x.navn || "",
                    primaryAddress: {
                      addressLine: x.forretningsadresse.adresse[0] || "",
                      postCode: x.forretningsadresse.postnummer || "",
                      city: x.forretningsadresse.poststed || "",
                      countryCode: x.forretningsadresse.landkode || "NO",
                    },
                    isCompany: true,
                    organizationNumber: x.organisasjonsnummer || "",
                  });

                  return newCustomer;
                }),
              );
              if (name) {
                setShowNameResults(true);
              }
              if (orgnr) {
                setShowOrgNumberResults(true);
              }
            } else {
              setShowNameResults(false);
              setShowOrgNumberResults(false);
            }
            break;
          case 400:
            console.error(
              "Validation error: Invalid company ID or request parameters.",
            );
            break;
          case 500:
            console.error("Internal server error. Please try again later.");
            break;
          default:
            console.error(`Unexpected response code: ${response.status}`);
            break;
        }
      } catch (error) {
        console.error("An error occurred while fetching the data:", error);
      }
    } else {
      setSearchResults(undefined);
      setShowNameResults(false);
      setShowOrgNumberResults(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (!isNew) {
      setIsCompany(props.data?.isCompany ?? false);
    }
  }, [props.data?.isCompany, isNew]);

  const getValidationResults = () => {
    return [
      {
        id: "name",
        show: isValidated,
        isInvalid:
          isCompany &&
          !(
            validateHasValue(customer.name) &&
            validateStringLength(customer.name ?? "", 1)
          ),
        errorMessage: t("common:errors.fields.not_empty"),
      },
      {
        id: "firstName",
        show: isValidated,
        isInvalid:
          !isCompany &&
          !(
            validateHasValue(customer.firstName) &&
            validateStringLength(customer.firstName ?? "", 2)
          ),
        errorMessage: t("common:errors.fields.not_empty"),
      },
      {
        id: "lastName",
        show: isValidated,
        isInvalid:
          !isCompany &&
          !(
            validateHasValue(customer.lastName) &&
            validateStringLength(customer.lastName ?? "", 2)
          ),
        errorMessage: t("common:errors.fields.not_empty"),
      },
      {
        id: "organizationNumber",
        show: isValidated,
        isInvalid:
          isCompany &&
          !(
            validateHasValue(customer.organizationNumber) &&
            validateOrganizationNumber(customer.organizationNumber ?? "")
          ),
        errorMessage: t("common:errors.fields.not_empty"),
      },
      {
        id: "invoiceEmail",
        show: isValidated,
        isInvalid: !(
          validateHasValue(customer.invoiceEmail) &&
          validateEmail(customer.invoiceEmail ?? "")
        ),
        errorMessage: t("common:errors.fields.email"),
      },
      {
        id: "contactEmail",
        show: isValidated,
        isInvalid: !(
          validateHasValue(customer.contactEmail) &&
          validateEmail(customer.contactEmail ?? "")
        ),
        errorMessage: t("common:errors.fields.email"),
      },
      {
        id: "phoneNumber",
        show: isValidated,
        isInvalid: !(
          validateHasValue(customer.phoneNumber) &&
          validateMobile(customer.phoneNumber ?? "")
        ),
        errorMessage: t("common:errors.fields.phone"),
      },
      {
        id: "primaryAddress.postCode",
        show: isValidated,
        isInvalid: !(
          validateHasValue(customer.primaryAddress?.postCode) &&
          validatePostCode(customer.primaryAddress?.postCode ?? "")
        ),
        errorMessage: t("common:errors.fields.not_empty"),
      },
      {
        id: "primaryAddress.city",
        show: isValidated,
        isInvalid: !(
          validateHasValue(customer.primaryAddress?.city) &&
          validateStringLength(customer.primaryAddress?.city ?? "", 2)
        ),
        errorMessage: t("common:errors.fields.email"),
      },
    ];
  };

  const validateAndConfirm = () => {
    const results = getValidationResults();
    setValidations(results);
    // If valid form, we trigger the onConfirm
    customer.isCompany = isCompany;
    if (!hasValidationError(results)) props.onConfirm(customer);
  };

  useEffect(() => {
    setIsValidated(true);
  }, [setValidations, validations]);

  return (
    <StandardForm
      showHeader={false}
      description={t("modals:descriptions.new_customer")}
      fields={[
        {
          id: "isCompany",
          grid_style: "col-span-2",
          isToggle: true,
          input: (
            <ToggleSwitch
              id="isCompany"
              name="isCompany"
              checked={isCompany}
              label={t("common:is_company")}
              onChange={setIsCompany}
              color="green"
            />
          ),
        },
        {
          id: "name",
          label: t("common:company_name"),
          grid_style: "col-span-2",
          hidden: !isCompany,
          input: (
            <DropdownSearch
              value={customer.name || ""}
              clickOutside={setShowNameResults}
              placeholder={t("common:company_name")}
              inputChanged={(input) => {
                setIsLoading(true);
                dispatch({
                  type: "UPDATE_FIELD",
                  payload: { key: "name", value: input },
                });
                setShowNameResults(true);
                debouncedSearch(input || "");
              }}
              showResults={showNameResults}
              childrenAbsolute
            >
              <div>
                {isLoading && <TableSkeletonsByAmount quantity={3} />}
                {!isLoading &&
                  searchResults?.length &&
                  searchResults.map((customer: Customer) => {
                    return (
                      <DropdownSearchListItem
                        key={customer.organizationNumber}
                        buttonClickEvent={() => {
                          setShowNameResults(false);
                          dispatch({
                            type: "UPDATE_FIELD",
                            payload: { key: "name", value: customer.name },
                          });
                          dispatch({
                            type: "UPDATE_FIELD",
                            payload: {
                              key: "primaryAddress.addressLine",
                              value: customer.primaryAddress?.addressLine,
                            },
                          });
                          dispatch({
                            type: "UPDATE_FIELD",
                            payload: {
                              key: "primaryAddress.postCode",
                              value: customer.primaryAddress?.postCode,
                            },
                          });
                          dispatch({
                            type: "UPDATE_FIELD",
                            payload: {
                              key: "primaryAddress.city",
                              value: customer.primaryAddress?.city,
                            },
                          });
                          dispatch({
                            type: "UPDATE_FIELD",
                            payload: {
                              key: "primaryAddress.countryCode",
                              value: customer.primaryAddress?.countryCode,
                            },
                          });
                          dispatch({
                            type: "UPDATE_FIELD",
                            payload: {
                              key: "organizationNumber",
                              value: customer.organizationNumber,
                            },
                          });
                        }}
                        id={customer.id}
                        title={customer.name ?? ""}
                        subtitle={customer.organizationNumber ?? ""}
                      ></DropdownSearchListItem>
                    );
                  })}
              </div>
            </DropdownSearch>
          ),
        },
        {
          id: "firstName",
          label: t("common:firstname"),
          hidden: isCompany,
          input: (
            <StyledTextInput
              id="firstName"
              placeholder={t("common:placeholder.firstname")}
              onInputChanged={(input) =>
                dispatch({
                  type: "UPDATE_FIELD",
                  payload: { key: "firstName", value: input },
                })
              }
              defaultValue={customer.firstName ?? ""}
              validation={validations.find(
                (validation) => validation.id === "firstName",
              )}
            />
          ),
        },
        {
          id: "lastName",
          label: t("common:lastname"),
          hidden: isCompany,
          input: (
            <StyledTextInput
              id="lastName"
              placeholder={t("common:placeholder.lastname")}
              defaultValue={customer.lastName ?? ""}
              onInputChanged={(input) =>
                dispatch({
                  type: "UPDATE_FIELD",
                  payload: { key: "lastName", value: input },
                })
              }
              validation={validations.find(
                (validation) => validation.id === "lastName",
              )}
            />
          ),
        },
        {
          id: "primaryAddress.addressLine",
          label: t("common:address_line"),
          grid_style: "col-span-2",
          input: (
            <StyledTextInput //Uses initial value even if parent updates its state....... fix
              id="primaryAddress.addressLine"
              key={customer.organizationNumber}
              placeholder={t("common:placeholder.address_line")}
              onInputChanged={(input) =>
                dispatch({
                  type: "UPDATE_FIELD",
                  payload: { key: "primaryAddress.addressLine", value: input },
                })
              }
              defaultValue={customer.primaryAddress?.addressLine ?? ""}
              validation={validations.find(
                (validation) => validation.id === "primaryAddress.addressLine",
              )}
            />
          ),
        },
        {
          id: "primaryAddress.postCode",
          label: t("common:postcode"),
          input: (
            <StyledTextInput
              id="primaryAddress.postCode"
              key={customer.organizationNumber}
              placeholder={t("common:placeholder.postcode")}
              onInputChanged={(input) =>
                dispatch({
                  type: "UPDATE_FIELD",
                  payload: { key: "primaryAddress.postCode", value: input },
                })
              }
              defaultValue={customer.primaryAddress?.postCode ?? ""}
              validation={validations.find(
                (validation) => validation.id === "primaryAddress.postCode",
              )}
            />
          ),
        },
        {
          id: "primaryAddress.city",
          label: t("common:city"),
          input: (
            <StyledTextInput
              id="primaryAddress.city"
              key={customer.organizationNumber}
              placeholder={t("common:placeholder.city")}
              onInputChanged={(input) =>
                dispatch({
                  type: "UPDATE_FIELD",
                  payload: { key: "primaryAddress.city", value: input },
                })
              }
              defaultValue={customer.primaryAddress?.city ?? ""}
              validation={validations.find(
                (validation) => validation.id === "primaryAddress.city",
              )}
            />
          ),
        },
        {
          id: "primaryAddress.countryCode",
          label: t("common:country"),
          input: (
            <SelectCountry
              id="primaryAddress.countryCode"
              key={customer.organizationNumber}
              defaultValue={customer.primaryAddress?.countryCode ?? "NO"}
            />
          ),
        },
        {
          id: "organizationNumber",
          label: t("common:organization_number"),
          hidden: !isCompany,
          input: (
            <DropdownSearch
              value={customer.organizationNumber || ""}
              placeholder={t("common:organization_number")}
              inputChanged={(input) => {
                if (input.length === 9) {
                  setIsLoading(true);
                  setShowOrgNumberResults(true);
                  debouncedSearch(undefined, input || "");
                }
              }}
              showResults={showOrgNumberResults}
              childrenAbsolute
            >
              <div>
                {isLoading && <TableSkeletonsByAmount quantity={3} />}
                {!isLoading &&
                  searchResults?.length &&
                  searchResults.map((customer: Customer) => {
                    return (
                      <DropdownSearchListItem
                        key={customer.organizationNumber}
                        buttonClickEvent={() => {
                          setShowOrgNumberResults(false);
                          dispatch({
                            type: "UPDATE_STATE",
                            payload: {
                              key: "",
                              value: customer,
                            },
                          });
                        }}
                        id={customer.id}
                        title={customer.name ?? ""}
                        subtitle={customer.organizationNumber ?? ""}
                      ></DropdownSearchListItem>
                    );
                  })}
              </div>
            </DropdownSearch>
          ),
        },
        {
          id: "primaryAddress.careOf",
          label: t("common:care_of"),
          input: (
            <StyledTextInput
              id="primaryAddress.careOf"
              onInputChanged={(input) =>
                dispatch({
                  type: "UPDATE_FIELD",
                  payload: { key: "primaryAddress.careOf", value: input },
                })
              }
              placeholder={t("common:placeholder.care_of")}
              defaultValue={customer.primaryAddress?.careOf ?? ""}
            />
          ),
        },
        {
          id: "contactEmail",
          label: t("common:email"),
          input: (
            <StyledTextInput
              id="contactEmail"
              placeholder={t("common:placeholder.email")}
              onInputChanged={(input) =>
                dispatch({
                  type: "UPDATE_FIELD",
                  payload: { key: "contactEmail", value: input },
                })
              }
              defaultValue={customer.contactEmail ?? ""}
              validation={validations.find(
                (validation) => validation.id === "contactEmail",
              )}
            />
          ),
        },
        {
          id: "invoiceEmail",
          label: t("common:invoice_email"),
          input: (
            <StyledTextInput
              id="invoiceEmail"
              placeholder={t("common:placeholder.email")}
              onInputChanged={(input) =>
                dispatch({
                  type: "UPDATE_FIELD",
                  payload: { key: "invoiceEmail", value: input },
                })
              }
              defaultValue={customer.invoiceEmail ?? ""}
              validation={validations.find(
                (validation) => validation.id === "invoiceEmail",
              )}
            />
          ),
        },
        {
          id: "phoneNumber",
          label: t("common:phone"),
          input: (
            <StyledTextInput
              id="phoneNumber"
              placeholder={t("common:placeholder.phone")}
              defaultValue={customer.phoneNumber ?? ""}
              onInputChanged={(input) =>
                dispatch({
                  type: "UPDATE_FIELD",
                  payload: { key: "phoneNumber", value: input },
                })
              }
              validation={validations.find(
                (validation) => validation.id === "phoneNumber",
              )}
            />
          ),
        },
      ]}
      onConfirm={validateAndConfirm}
      onCancel={props.onCancel}
      buttonConfirmText={t("modals:button_texts.customer")}
      buttonConfirmPrependIcon={
        isNew ? <HiOutlinePlus className="mr-2 h-4 w-4" /> : null
      }
      buttonCloseText={t("common:close")}
    />
  );
};

export const CustomerModal: FC<CustomerModalProp> = function (
  props: CustomerModalProp,
) {
  const { t } = useTranslation(["common", "customer"]);

  return (
    <Dialog
      title={
        Customer.isNew(props.data)
          ? t("customer:new_customer")
          : t("customer:update_customer")
      }
      content={<CustomerForm {...props} />}
      show={props.isShowing}
      toggleModal={props.onCancel}
      hideButtons={true}
    />
  );
};
