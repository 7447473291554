import { type FC } from "react";
import { Label, Button, Tooltip } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import { handleNestedField } from "../../../helpers/handleNestedFields";
export interface FormFieldProp {
  id: string;
  label?: string | undefined;
  tooltip?: string | undefined;
  input: React.ReactNode;
  hidden?: boolean | false;
  isToggle?: boolean | false;
  grid_style?: string | undefined;
}

export interface StandardFormProp {
  buttonConfirmColor?: string;
  buttonConfirmText?: string;
  buttonConfirmPrependIcon?: React.ReactNode;
  buttonCloseText?: string;
  buttonClosePrependIcon?: React.ReactNode;
  hideButtons?: boolean;
  onCancel?: () => void;
  onConfirm?: (data: any) => void;
  showHeader?: boolean | false;
  header?: React.ReactNode;
  toggle?: FormFieldProp;
  fields: Array<FormFieldProp>;
  grid_style?: string;
  description?: string; // New optional description prop
}

export const StandardForm: FC<StandardFormProp> = (props) => {
  function handleSubmit(event: any) {
    // Prevent default behavior
    event.preventDefault();

    const formData = new FormData(event.target);
    var data = {};

    props.fields.forEach((field) => {
      // Bypass hidden fields
      if (field.hidden) return;

      const fieldId = field.id;
      let fieldData: any = formData.getAll(field.id);
      if (field.isToggle) {
        fieldData = fieldData.length === 1 && fieldData[0] === "on";
      } else if (fieldData.length === 1) {
        fieldData = fieldData[0];
      }

      data = handleNestedField(data, fieldId, fieldData);
    });

    if (props.onConfirm) props.onConfirm(data);
  }

  const dialogContent = (
    <div>
      {props.showHeader ? <div className="mb-0">{props.header}</div> : null}
      {props.description ? (
        <div className="text-lgb-grey-600 dark:text-white text-base pb-2 ">
          {props.description}
        </div>
      ) : null}
      <form onSubmit={handleSubmit} className="pt-4">
        <div
          className={
            props.grid_style ? props.grid_style : "grid grid-cols-2 gap-4"
          }
        >
          {props.toggle ? (
            <div className="col-span-full">
              <div key={props.toggle.id}>{props.toggle.input}</div>
            </div>
          ) : null}
          {props.fields.map((field) => {
            if (field.hidden) return null;
            return (
              <div key={field.id} className={field.grid_style ?? ""}>
                {field.label ? (
                  <div className="mb-2 block flex items-center">
                    <Label htmlFor={field.id} value={field.label} />
                    {field.tooltip ? (
                      <Tooltip content={field.tooltip}>
                        <HiInformationCircle className="ml-1 h-4 w-4 text-lgb-grey-400" />
                      </Tooltip>
                    ) : null}
                  </div>
                ) : null}
                {field.input}
              </div>
            );
          })}
        </div>
        {!props.hideButtons && (
          <div className="pt-6 flex gap-4">
            <Button
              color={
                props.buttonConfirmColor ? props.buttonConfirmColor : "primary"
              }
              type="submit"
            >
              {props.buttonConfirmPrependIcon}
              {props.buttonConfirmText}
            </Button>
            <Button
              color="gray"
              onClick={() => (props.onCancel ? props.onCancel() : null)}
            >
              {props.buttonClosePrependIcon}
              {props.buttonCloseText}
            </Button>
          </div>
        )}
      </form>
    </div>
  );

  return <div className="pb-4">{dialogContent}</div>;
};
