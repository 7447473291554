import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { Order } from "../models";
import { OrderStatus } from "../types/order/IOrder";
import { useCache } from "../context/CacheContext";
import firebase from "firebase/compat/app";

export const useOrders = (
  workspaceId: string,
  statuses: OrderStatus[] = [],
  additionalWhere: [string, firebase.firestore.WhereFilterOp, string][] = [], // If we want to add another filter
) => {
  const { cacheKey } = useCache();
  return useFirestoreQueryData(
    [Order.collectionName, cacheKey, workspaceId, statuses],
    Order.list(workspaceId, statuses, additionalWhere),
    {
      subscribe: false,
    },
  );
};
